import { SessionUserLogger, User } from '@/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from './config.service';
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class ApiWebService {

    private authTokenWeb: string;
    public isLoadUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isDataUser: BehaviorSubject<SessionUserLogger> = new BehaviorSubject<SessionUserLogger>(null);
    public isTokenWeb: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isDataWeb: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private http: HttpClient,
        private config: ConfigurationService
    ) { }

    /************************************************************* */
    public logout() {
        let dayNow: any = DateTime.now().toFormat('dd-LL-y');

        localStorage.removeItem(`_t${dayNow}`);
        localStorage.removeItem(`_u${dayNow}`);
        localStorage.removeItem(`_w${dayNow}`);
        this.setIsLoadUser(false);
        this.setIsDataUser(null);
        this.setIsTokenWeb('');
        this.setIsDataWeb(null);
        this.router.navigate(['/login']);
    }

    /************************************************************* */
    public setIsLoadUser(token: any) {
        this.isLoadUser.next(token);
    }

    public getIsLoadUser() {
        return this.isLoadUser.asObservable();
    }

    /************************************************************* */
    public setIsDataUser(data: any) {
        this.isDataUser.next(data);
    }

    public getIsDataUser() {
        return this.isDataUser.asObservable();
    }

    /************************************************************* */
    public setIsTokenWeb(token: any) {
        this.isTokenWeb.next(token);
    }

    public getIsTokenWeb() {
        return this.isTokenWeb.asObservable();
    }

    /************************************************************* */
    public setIsDataWeb(data: any) {
        this.isDataWeb.next(data);
    }

    public getIsDataWeb() {
        return this.isDataWeb.asObservable();
    }


}
