import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { SessionUserLogger } from '@/utils';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiWebService } from '@services/api-web.service';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;

    public data: SessionUserLogger;
    public pathIgUser: string = 'assets/img/default-profile.png';
    public ui: Observable<UiState>;
    public menu = [];

    constructor(
        private store: Store<AppState>,
        private _dataApiSession: ApiWebService
    ) { }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });

        this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.data = response);
        this.menu = MENU.map(x => {
            return {
                ...x,
                view: (x.nivelAcces == 1) ? (this.data?.user?.idTypeUser == 1) : true
            }
        })
    }
}

export const MENU = [
    {
        name: 'Inicio',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/'],
        nivelAcces: 0
    },
    {
        name: 'Clientes',
        iconClasses: 'fas fa-user-tie',
        path: ['/client'],
        nivelAcces: 0
    },
    {
        name: 'Promociones',
        iconClasses: 'fas fa-star',
        path: ['/promotions'],
        nivelAcces: 1
    },
    {
        name: 'Configuración',
        iconClasses: 'fas fa-cogs',
        nivelAcces: 1,
        children: [
            {
                name: 'Accesos',
                iconClasses: 'fas fa-user-shield',
                path: ['/Access']
            },
            {
                name: 'Entidades',
                iconClasses: 'fas fa-table',
                path: ['/entities']
            },
            {
                name: 'Auditoría',
                iconClasses: 'fab fa-keycdn',
                path: ['/audit-system']
            }
        ]
    }
];
