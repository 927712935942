import { map } from 'lodash';
import { Model } from '../model';

export class CampaignDTO extends Model {
    public id: number;
    public status: number;
    public campaign: string;
    public idSponsor: number;
    public nameSponsor: string;    
    public fechaReg: any;
    public year: number;
    public obse: string;
    public userReg: any;
    public fechaIni: any;
    public fechaFin: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.campaign = this.campaign || '';
        this.idSponsor = this.idSponsor || 0;
        this.nameSponsor = this.nameSponsor || '';
        this.fechaReg = this.status || '';
        this.userReg = this.userReg || '';
        this.year = this.year || 0;
        this.obse = this.obse || '';
        this.fechaIni = this.fechaIni || '';
        this.fechaFin = this.fechaFin || '';
    }

    public static cast(data: object): CampaignDTO {
        return new CampaignDTO(data);
    }

    public static casts(data: object[]): CampaignDTO[] {
        return map(data, c => new CampaignDTO(c));
    }
}

export class TemplateSmsDTO extends Model {
    public id: number;
    public status: number;
    public idTypeSms: number;
    public nameTypeSms: string;    
    public name: string;    
    public template: string;    
    public fechaReg: any;
    public userReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idTypeSms = this.idTypeSms || 0;
        this.nameTypeSms = this.nameTypeSms || '';
        this.name = this.name || '';
        this.template = this.template || '';
        this.fechaReg = this.status || '';
        this.userReg = this.userReg || '';
    }

    public static cast(data: object): TemplateSmsDTO {
        return new TemplateSmsDTO(data);
    }

    public static casts(data: object[]): TemplateSmsDTO[] {
        return map(data, c => new TemplateSmsDTO(c));
    }
}

export class PoliciesConditionsDTO extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public nameCampaign: string;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.nameCampaign = this.nameCampaign || '';
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.status || '';
        this.userReg = this.userReg || '';
    }

    public static cast(data: object): PoliciesConditionsDTO {
        return new PoliciesConditionsDTO(data);
    }

    public static casts(data: object[]): PoliciesConditionsDTO[] {
        return map(data, c => new PoliciesConditionsDTO(c));
    }
}

export class LegalNoticesDTO extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public nameCampaign: string;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.nameCampaign = this.nameCampaign || '';
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.status || '';
        this.userReg = this.userReg || '';
    }

    public static cast(data: object): LegalNoticesDTO {
        return new LegalNoticesDTO(data);
    }

    public static casts(data: object[]): LegalNoticesDTO[] {
        return map(data, c => new LegalNoticesDTO(c));
    }
}

export class CompetitionRulesDTO extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public nameCampaign: string;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.nameCampaign = this.nameCampaign || '';
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.status || '';
        this.userReg = this.userReg || '';
    }

    public static cast(data: object): CompetitionRulesDTO {
        return new CompetitionRulesDTO(data);
    }

    public static casts(data: object[]): CompetitionRulesDTO[] {
        return map(data, c => new CompetitionRulesDTO(c));
    }
}