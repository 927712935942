import { map } from 'lodash';
import { Model } from '../model';

export class Promotion extends Model {
    public id: number;
    public status: number;
    public name: string;
    public titlePromo: string;
    public idCampania: number;
    public idPoliticas: number;
    public idBaseConcurso: number;
    public idClasPromo: number;
    public pathGrandeImg: string;
    public pathMiniImg: string;
    public pathImgFondo: string;
    public pathImgBanner: string;
    public linkWeb: string;
    public descrip: string;
    public fechaIni: any;
    public fechaFin: any;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.name = this.name || '';
        this.titlePromo = this.titlePromo || '';
        this.idCampania = this.idCampania || 0;
        this.idPoliticas = this.idPoliticas || 0;
        this.idBaseConcurso = this.idBaseConcurso || 0;
        this.idClasPromo = this.idClasPromo || 0;
        this.pathGrandeImg = this.pathGrandeImg || '';
        this.pathMiniImg = this.pathMiniImg || '';
        this.pathImgFondo = this.pathImgFondo || '';
        this.pathImgBanner = this.pathImgBanner || '';
        this.linkWeb = this.linkWeb || '';
        this.descrip = this.descrip || '';
        this.fechaIni = this.fechaIni || null;
        this.fechaFin = this.fechaFin || null;
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): Promotion {
        return new Promotion(data);
    }

    public static casts(data: object[]): Promotion[] {
        return map(data, c => new Promotion(c));
    }
}

export class ClassificationPromo extends Model {
    public id: number;
    public nameCls: string;
    public status: number;
    public fechReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.nameCls = this.nameCls || '';
        this.status = this.status || 0;
        this.fechReg = this.fechReg || ''
    }

    public static cast(data: object): ClassificationPromo {
        return new ClassificationPromo(data);
    }

    public static casts(data: object[]): ClassificationPromo[] {
        return map(data, c => new ClassificationPromo(c));
    }
}