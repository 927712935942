import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { ResponseQueryGeneral } from '@/utils';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrl(): string {
        return this.config.result.apiUrl + 'auth/';
    }

    public logIn(data: any): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}logIn`;
        return this.http.post(endpoint, data).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public getDataUser(data: any): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}getDataUser`;
        return this.http.post(endpoint, data).pipe(map((res: ResponseQueryGeneral) => res));
    }

}
