import { Campaign, CampaignDTO, PoliciesConditions, PoliciesConditionsDTO, ResponseQueryCampaign, ResponseQueryGeneral, ResponseQueryPoliciesConditions, SessionUserLogger } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PoliciesConditionsService } from '@services/policies-conditions.service';
import { ConfigurationService } from '@services/config.service';
import { CampaignService } from '@services/campaign.service';
import { ApiWebService } from '@services/api-web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";

@Component({
  selector: 'app-policies-conditions',
  templateUrl: './policies-conditions.component.html',
  styleUrls: ['./policies-conditions.component.scss']
})
export class PoliciesConditionsComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  bodyHtml: string;
  nameItem: string;
  rgRegistry: FormGroup;
  dataUserLogger: SessionUserLogger;
  listCampaigns: CampaignDTO[] = [];
  listPoliciesConditions: PoliciesConditionsDTO[] = [];
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  @ViewChild('ModalDetailPC') mdlDtlPC: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };
  configSummerNote: any;

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _dataApiSession: ApiWebService,
    private _dataConfig: ConfigurationService,
    private _campaignService: CampaignService,
    private _policeSrv: PoliciesConditionsService
  ) {
    this.getSetting();
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    this.subscriptions.add(this.getAllDataCampaigns());
  }

  public listRecords() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listPoliciesConditions = [];
    return this._policeSrv.getAll().subscribe((result: ResponseQueryPoliciesConditions) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listPoliciesConditions = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public getAllDataCampaigns(): Subscription {
    this.listCampaigns = [];
    return this._campaignService.getAll().subscribe((result: ResponseQueryCampaign) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCampaigns = result?.result?.data.filter(x => x.status == 1);          
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public getSetting() {
    this.configSummerNote = {
      placeholder: '',
      tabsize: 2,
      height: '350px',
      uploadImagePath: '/api/upload',
      codemirror: { // codemirror options
        theme: 'monokai'
      },
      toolbar: [
        ['misc', ['codeview', 'undo', 'redo']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']],
        ['para', ['style', 'ul', 'ol', 'paragraph', 'height']]
      ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    }
  }

  private loadForms() {
    const model = new PoliciesConditionsDTO();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: PoliciesConditionsDTO): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      idCampaign: new FormControl(
        {
          value: model.idCampaign,
          disabled: false
        },
        Validators.required
      ),
      name: new FormControl(
        {
          value: model.name,
          disabled: false
        },
        Validators.required
      ),
      textHtml: new FormControl(
        {
          value: model.textHtml,
          disabled: false
        },
        Validators.required
      )
    }
  }

  public openModalFormEdit(typeForm: string, dataForm: PoliciesConditionsDTO = null) {
    this.setDataFormEdit(typeForm, dataForm);
    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, dataForm: PoliciesConditionsDTO) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVAS';
        let tmpUser: PoliciesConditionsDTO = new PoliciesConditionsDTO();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpUser,
          fechaReg: DateTime.now(),
          userReg: this.dataUserLogger?.user?.id
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...dataForm,  
          status: (dataForm.status == 1) ? true : false,
          id: Number(dataForm.id)
        })
        break;

      default:
        break;
    }
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: PoliciesConditionsDTO = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    let polices: PoliciesConditions = new PoliciesConditions(
      {
        id: dataForm.id,
        status:dataForm.status,
        idCampaign: Number(dataForm.idCampaign),
        name: dataForm.name,
        textHtml: dataForm.textHtml,
        fechaReg: dataForm.fechaReg,
        userReg: dataForm.userReg,
      }
    )

    if(polices.id == 0){
      this.subscriptions.add(this.postSaveData(polices));
    }else{
      this.subscriptions.add(this.postEditData(polices));
    }
  }

  public openModalFormDelete(data: PoliciesConditionsDTO) {
    this.nameItem = data.name;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => {
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }

  public openModalDetailTemplate(dataForm: PoliciesConditionsDTO) {
    this.bodyHtml = dataForm.textHtml;
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.mdlDtlPC, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private postSaveData(data: PoliciesConditions): Subscription {
    return this._policeSrv.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: PoliciesConditions): Subscription {
    return this._policeSrv.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: PoliciesConditions): Subscription {
    return this._policeSrv.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }


}
