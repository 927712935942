import { Campaign, Client, ClientDTO, logSendSmsDTO, ResponseQueryClient, ResponseQueryGeneral, ResponseQueryLogSms, SessionUserLogger, TemplateSMS, TextTypeSMS } from '@/utils';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { LogSendSmsService } from '@services/log-send-sms.service';
import { ConfigurationService } from '@services/config.service';
import { ApiWebService } from '@services/api-web.service';
import { ClientService } from '@services/client.service';
import { ToolsService } from '@services/tools.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy {

  modalClient: string;
  modalMovil: string;
  typeFormTitle: string;
  nameItem: string;
  objLog: logSendSmsDTO;
  rgRegistry: FormGroup;
  rgRegistrySendSMS: FormGroup;
  dataUserLogger: SessionUserLogger;
  listClients: ClientDTO[] = [];
  listLogsSms: logSendSmsDTO[] = [];
  listTblsWebs: any[] = [];
  documents: any[] = [];
  cities: any[] = [];

  campaigns: Campaign[] = [];
  textTypeSMS: TextTypeSMS[] = [];
  templateSMS_tmp: TemplateSMS[] = [];
  templateSMS: TemplateSMS[] = [];

  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalLogsSMS') mdlLogsSMS: TemplateRef<any>;
  @ViewChild('ModalSendSMS') mdlSendSMS: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  @ViewChild('ModalFormLog') mdlFormLog: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private router: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _clientSvr: ClientService,
    private _toolsService: ToolsService,
    private _clientService: ClientService,
    private _dataApiSession: ApiWebService,
    private _logSendSmsSrv: LogSendSmsService,
    private _dataConfig: ConfigurationService,
  ) {
    this.loadForms();
    this.loadFormsSendSMS();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    this.subscriptions.add(this.getAllDataWebs());

    this.changeComboBox();
  }

  private changeComboBox() {
    this.rgRegistry.get('fechaNac').valueChanges.subscribe((fechaNaci: any) => {

      let OldYear: number = Number(DateTime.fromISO(fechaNaci).toFormat('y'));
      let currentYear: number = Number(DateTime.now().toFormat('y'));
      let ageClient: number = currentYear - OldYear;

      if (ageClient < 18) {
        this._mf.showToastrWarning('VALIDACIÓN', 'El cliente no puede ser menor de edad');
        return;
      }

      if (ageClient <= 0) {
        this._mf.showToastrWarning('VALIDACIÓN', 'La edad del cliente es invalido');
        return;
      }

      if (OldYear >= currentYear) {
        this._mf.showToastrWarning('VALIDACIÓN', 'La fecha seleccionada no puede ser mayor o igual a la fecha actual');
        return;
      }

      this.rgRegistry.patchValue({
        year: ageClient
      })

    })

    this.rgRegistrySendSMS.get('idCampaign').valueChanges.subscribe((idCampaign: any) => {
      let templateSelected: Campaign = this.campaigns.find(x => x.id == idCampaign);

      this.rgRegistrySendSMS.patchValue({
        nameCampaign: templateSelected?.campaign
      });
    })

    this.rgRegistrySendSMS.get('idTypeSMS').valueChanges.subscribe((idTypeSMS: any) => {
      let textTypeSMSSelected: TextTypeSMS = this.textTypeSMS.find(x => x.id == idTypeSMS);

      this.templateSMS = this.templateSMS_tmp.filter(x => x.idTypeSms == idTypeSMS);
      this.rgRegistrySendSMS.patchValue({
        nameTypeSMS: textTypeSMSSelected?.typeSms,
        bodySMS: ''
      });
    })

    this.rgRegistrySendSMS.get('idTemplateSMS').valueChanges.subscribe((idTemplateSMS: any) => {
      let templateSelected: TemplateSMS = this.templateSMS.find(x => x.id == idTemplateSMS);

      this.rgRegistrySendSMS.patchValue({
        templateSMSName: templateSelected?.name,
        bodySMS: templateSelected?.template
      });
    })

  }

  private loadForms() {
    const model = new ClientDTO();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: ClientDTO): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      name: new FormControl(
        {
          value: model.name,
          disabled: false
        },
        [Validators.required, Validators.maxLength(20)]
      ),
      surname: new FormControl(
        {
          value: model.surname,
          disabled: false
        },
        [Validators.required, Validators.maxLength(20)]
      ),
      idTypeDoc: new FormControl(
        {
          value: model.idTypeDoc,
          disabled: false
        },
        Validators.required
      ),
      nroDoc: new FormControl(
        {
          value: model.nroDoc,
          disabled: false
        },
        [Validators.required, Validators.maxLength(15)]
      ),
      fechaNac: new FormControl(
        {
          value: model.fechaNac,
          disabled: false
        },
        Validators.required
      ),
      year: new FormControl(
        {
          value: model.year,
          disabled: true
        },
        Validators.required
      ),
      phone: new FormControl(
        {
          value: model.phone,
          disabled: false
        },
        [Validators.required, Validators.maxLength(10)]
      ),
      movil: new FormControl(
        {
          value: model.movil,
          disabled: false
        },
        [Validators.required, Validators.maxLength(10)]
      ),
      codPostal: new FormControl(
        {
          value: model.codPostal,
          disabled: false
        },
        [Validators.required, Validators.maxLength(5)]
      ),
      // idCity: new FormControl(
      //   {
      //     value: model.idCity,
      //     disabled: false
      //   },
      //   Validators.required
      // ),
      codeReg: new FormControl(
        {
          value: model.codeReg,
          disabled: false
        },
        Validators.required
      )
    }
  }

  private loadFormsSendSMS() {
    const model = new logSendSmsDTO();
    const formGroupData = this.getFormGroupDataSms(model);
    this.rgRegistrySendSMS = this._formBuilder.group(formGroupData);
  }

  private getFormGroupDataSms(model: logSendSmsDTO): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      nameClient: new FormControl(
        {
          value: model.nameClient,
          disabled: true
        },
        Validators.required
      ),
      movilClient: new FormControl(
        {
          value: model.movilClient,
          disabled: true
        },
        Validators.required
      ),
      bodySMS: new FormControl(
        {
          value: model.bodySMS,
          disabled: false
        },
        [Validators.required, Validators.maxLength(160)]
      )
    }
  }


  public listRecords() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listClients = [];
    return this._clientService.getAll().subscribe((result: ResponseQueryClient) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listClients = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  private getAllDataWebs(): Subscription {
    this.listTblsWebs = [];
    return this._toolsService.dataCompanyWeb().subscribe((result: ResponseQueryGeneral) => {
      if (result?.response) {
        this.documents = result?.result?.documents.filter(x => x.status == 1);
        this.cities = result?.result?.cities;
        this.campaigns = result?.result?.campaigns.filter(x => x.status == 1);
        this.textTypeSMS = result?.result?.textTypeSMS.filter(x => x.status == 1);
        this.templateSMS_tmp = result?.result?.templateSMS.filter(x => x.status == 1);
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  public openModalFormEdit(typeForm: string, dataForm: ClientDTO = null) {
    this.subscriptions.add(this.getAllDataWebs());
    this.setDataFormEdit(typeForm, dataForm);
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, dataForm: ClientDTO) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        let tmpClient: ClientDTO = new ClientDTO();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpClient,
          fechaReg: DateTime.now(),
          userReg: this.dataUserLogger?.user?.id
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...dataForm,
          id: Number(dataForm.id),
          status: (dataForm.status == 1) ? true : false,
        })
        break;

      default:
        break;
    }
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    if (this.rgRegistry.getRawValue().year == 0) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: ClientDTO = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    let client: Client = new Client({
      id: dataForm.id,
      status: dataForm.status,
      name: dataForm.name,
      surname: dataForm.surname,
      idTypeDoc: Number(dataForm.idTypeDoc),
      nroDoc: dataForm.nroDoc,
      fechaNac: dataForm.fechaNac,
      year: dataForm.year,
      email: dataForm.email,
      phone: dataForm.phone,
      movil: dataForm.movil,
      codPostal: dataForm.codPostal,
      idCity: Number(dataForm.idCity),
      fechaReg: dataForm.fechaReg,
      userReg: dataForm.userReg
    })

    if (client.id == 0) {
      this.subscriptions.add(this.postSaveData(client));
    } else {
      this.subscriptions.add(this.postEditData(client));
    }
  }

  public openModalLogsSms(dataForm: ClientDTO) {
    this.getDataLogSendSms(dataForm);
    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlLogsSMS, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  public openModalSendSms(dataForm: ClientDTO) {
    if (dataForm.codeReg == 0) {
      this._mf.showToastrWarning('VALIDACIÓN DE CLIENTE', 'Cliente no cuenta con CODIGO AUTOGENERADO DE REGISTRO, Por favor debe ser eliminado y que el mismo cliente vuelva a REGISTRARSE. Gracias')
      return;
    };

    this.setFormSMS(dataForm);
    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlSendSMS, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setFormSMS(dataForm: ClientDTO) {
    let tmp: ClientDTO = new ClientDTO();
    this.rgRegistrySendSMS.reset();
    this.rgRegistrySendSMS.patchValue({
      idCliente: dataForm.id,
      nameClient: `${dataForm.name} ${dataForm.surname}`,
      movilClient: dataForm.movil,
      codPostalClient: dataForm.codPostal
    })
  }

  public openModalFormDelete(data: ClientDTO) {
    this.nameItem = data.name;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => {
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }

  public openModalFormLog(logs: logSendSmsDTO) {
    this.objLog = new logSendSmsDTO({ ...logs });
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormLog, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  private postSaveData(data: Client): Subscription {
    return this._clientSvr.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  private postEditData(data: Client): Subscription {
    return this._clientSvr.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  private deleteData(data: Client): Subscription {
    return this._clientSvr.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  public onclickSaveDataSendSMS() {
    if (this.rgRegistrySendSMS.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    let sms: logSendSmsDTO = this.rgRegistrySendSMS.getRawValue();
    sms.movilClient = `${this.rgRegistrySendSMS.getRawValue().codPostalClient}${this.rgRegistrySendSMS.getRawValue().movilClient}`;
    sms.fechaReg = DateTime.now();
    sms.userReg = this.dataUserLogger?.user?.id;

    this.sendSMSclient(sms)
  }

  private sendSMSclient(sms: logSendSmsDTO) {
    let data = {
      dnis: sms.movilClient,
      message: sms.bodySMS,
      ani: '6001',
    }

    this._clientSvr.sendSMSclient(data).subscribe((response: any) => {
      console.log(response);
      if (response.message_id) {
        this._mf.showToastrSuccess('SMS ENVIADO', '');
        this.saveSMSsend(sms, 'ENVIADO');
      } else {
        this._mf.showToastrWarning('SMS RECHAZADO', '');
        this.saveSMSsend(sms, 'RECHAZADO');
      }
    }, err => {
      console.log(err);
      this._mf.showToastrWarning('SMS RECHAZADO', '');
      this.saveSMSsend(sms, 'RECHAZADO');
    })
  }

  private saveSMSsend(sms: logSendSmsDTO, status: string) {
    sms.status = status;
    this._logSendSmsSrv.register(sms).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('LOGS DE SMS ACTUALIZADOS', 'Se ha registrado un nuevo log para el envío de SMS');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  private getDataLogSendSms(dataForm: ClientDTO) {
    this.modalClient = `${dataForm.name} ${dataForm.surname}`;
    this.modalMovil = dataForm.movil;

    this._logSendSmsSrv.getAll().subscribe((result: ResponseQueryLogSms) => {
      if (result.response) {
        this.listLogsSms = result.result.data.filter(x => x.idCliente == dataForm.id);
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.router.navigate(['/login']);
      }
    })
  }



}
