import { SessionUserLogger } from '@/utils';
import { Component, OnInit } from '@angular/core';
import { ApiWebService } from '@services/api-web.service';
import { AppService } from '@services/app.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    public data: SessionUserLogger;
    public pathIgUser: string = 'assets/img/default-profile.png';

    constructor(
        private _dataApiSession: ApiWebService
    ) {
        this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.data = response);
    }

    ngOnInit(): void {

    }

    logout() {
        this._dataApiSession.logout();
    }

    public formatDate(date: any) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
