import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/setting/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { EntitiesComponent } from '@pages/setting/entities/entities.component';
import { UsersComponent } from '@pages/setting/access-system/users/users.component';
import { EmployeesComponent } from '@pages/setting/access-system/employees/employees.component';
import { AccessSystemComponent } from '@pages/setting/access-system/access-system.component';
import { CampaignComponent } from '@pages/setting/entities/campaign/campaign.component';
import { SmsTemplatesComponent } from '@pages/setting/entities/sms-templates/sms-templates.component';
import { SmsTypeComponent } from '@pages/setting/entities/sms-type/sms-type.component';
import { WebPagePromoComponent } from '@pages/web-page-promo/web-page-promo.component';
import { PoliciesConditionsComponent } from '@pages/setting/entities/policies-conditions/policies-conditions.component';
import { ClientsComponent } from '@pages/clients/clients.component';
import { PromotionsComponent } from '@pages/promotions/promotions.component';
import { SponsorsComponent } from '@pages/setting/entities/sponsors/sponsors.component';
import { CompetitionRulesComponent } from '@pages/setting/entities/competition-rules/competition-rules.component';
import { AuditSystemComponent } from '@pages/setting/audit-system/audit-system.component';
import { ModalAlertSystemComponent } from '@components/modal-alert-system/modal-alert-system.component';

import { NgxSummernoteModule } from 'ngx-summernote';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LegalNoticesComponent } from '@pages/setting/entities/legal-notices/legal-notices.component';

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

let MyComponents = [
    ClientsComponent,    
    EntitiesComponent,   
    CampaignComponent,
    SmsTemplatesComponent,
    SmsTypeComponent, 
    AccessSystemComponent,
    EmployeesComponent,
    PromotionsComponent,
    WebPagePromoComponent,
    PoliciesConditionsComponent,
    LegalNoticesComponent,
    SponsorsComponent,
    CompetitionRulesComponent,
    UsersComponent,
    ModalAlertSystemComponent,
    AuditSystemComponent
]

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        MyComponents
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        // ToastrModule.forRoot({
        //     timeOut: 3000,
        //     positionClass: 'toast-top-right',
        //     preventDuplicates: true
        // }),
        ProfabricComponentsModule,
        NgbModule,
        NgxSummernoteModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
