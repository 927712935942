<section class="content-header animate__animated animate__fadeIn">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="title_windows"><b><i class="fas fa-star mr-1"></i>Promociones</b></h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Promociones</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <div class="card mb-2 animate__animated animate__fadeIn">
      <div class="card-body border_top_card p-2">
        <div class="row row_search_card">
          <div class="col-sm-5">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                  BUSCAR
                </button>
                <ul class="dropdown-menu">
                  <li class="dropdown-item"><a>Nombre Promoción</a></li>
                  <li class="dropdown-item"><a>Campaña</a></li>
                </ul>
              </div>
              <input type="text" class="form-control">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="row_btn_new">
              <button type="button" class="btn btn-success btn-sm mr-1" data-toggle="tooltip" data-placement="bottom"
                title="Nuevo registro" (click)="openModalFormEdit('new')">
                <i class="fas fa-plus mr-1"></i>
                <span>NUEVO</span>
              </button>
              <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip" data-placement="bottom"
                title="Listar registros" (click)="listarRegistros()">
                <i class="fas fa-sync-alt"></i>
              </button>
              <!-- <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip" data-placement="bottom"
                title="Generar Reporte">
                <i class="fas fa-cloud-download-alt"></i>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_cards card_scroll">
      <div class="card animate__animated animate__fadeIn" data-toggle="tooltip" data-placement="bottom" title="{{promo.name}}"
        *ngFor="let promo of listPromos">
        <div class="card_img">
          <img [src]="_toolsService.linkPublicImgGrangePromo(promo.pathGrandeImg)" alt="{{promo.pathGrandeImg}}" loading="lazy"
            (click)="openModalViewImgPromo(promo)" onerror="this.onerror=null;this.src='assets/img/no-img.png'">
        </div>
        <div class="card_text">
          <span class="d-flex align-items-center">
            <i class="fas fa-record-vinyl mr-1"
            [ngClass]="{'status_active': promo.status == 1, 'status_inactive': promo.status == 0}"
            data-toggle="tooltip" data-placement="bottom" title="ACTIVO"></i>
            <span class="name_promo">{{promo.name}}</span>
          </span>
          <div class="d-flex align-items-center">
            <div ngbDropdown class="d-inline-block">
              <span class="fecha_promo" ngbDropdownToggle>{{promo.fechaReg}}</span>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem style="font-size: 13px;" (click)="openModalFormEdit('edit', promo)">
                  <i class="fas fa-edit mr-1"></i>Editar
                </button>
                <button ngbDropdownItem style="font-size: 13px;" (click)="openModalFormDelete(promo)">
                  <i class="fas fa-trash-alt mr-1"></i>Eliminar
                </button>
                <button ngbDropdownItem style="font-size: 13px;" (click)="openModalFormPoliciesConditions(promo.idPoliticas)">
                  <i class="fas fa-lock mr-1"></i>Políticas y condiciones
                </button>
                <button ngbDropdownItem style="font-size: 13px;" (click)="openModalFormBaseConcurso(promo.idBaseConcurso)">
                  <i class="far fa-file-alt mr-1"></i>Base del concurso
                </button>
                <!-- <button ngbDropdownItem style="font-size: 13px;" (click)="openModalPageRedirect(promo)">
                  <i class="fas fa-globe mr-1"></i>Ver página en línea
                </button> -->
                <button ngbDropdownItem style="font-size: 13px;" (click)="openModalGetLink(promo)">
                  <i class="fas fa-link mr-1"></i>Obtener Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<!---MODAL DE ELIMINAR --->
<ng-template #ModalDelete let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-trash-alt mr-1"></i> ELIMINAR PROMOCIÓN
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
      <div class="row ml-0 mr-0">
        <div class="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 pr-0 pl-0" style="height: 70px;">
          <img src="assets/img/imgEliminar.png" class="img_delete" alt="imgEliminar.png">
        </div>
        <div class="col-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
          <div class="row">
            <p class="text_query_delete">¿Desea eliminar la promoción seleccionado <b>{{nameItem}}</b>?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" (click)="modal.dismiss('delete')">
      <i class="fas fa-check mr-1"></i> SI
    </button>
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> NO
    </button>
  </div>
</ng-template>

<!---MODAL DE EDICIÓN --->
<ng-template #ModalFormEdit let-modal>
  <form autocomplete="off" [formGroup]="rgRegistry" (ngSubmit)="onclickSaveData()">
    <div class="modal-header myModal-header">
      <h4 class="modal-title">
        <i class="fas fa-edit mr-1"></i> {{typeFormTitle}} PROMOCIÓN
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body myModal-body">
      <div class="row">
        <div class="col-6">
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-7">
              <div class="form-group row m-0">
                <label for="id" class="col-sm-7 col-form-label col-form-label-sm">Codigo</label>
                <div class="col-sm-5">
                  <input type="text" class="form-control form-control-sm" id="id" formControlName="id" readonly>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group row m-0">
                <div class="col-sm-5">
                  <div class="form-check text-right">
                    <input class="form-check-input" type="checkbox" id="status" formControlName="status">
                    <label class="form-check-label" for="status">
                      Activo
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="idCampania" class="col-sm-4 col-form-label col-form-label-sm">Campaña</label>
                <div class="col-sm-8">
                  <select class="form-control form-control-sm" id="idCampania" formControlName="idCampania">
                    <option *ngFor="let campaign of listCampaigns" value="{{campaign.id}}">{{campaign.id}}-{{campaign.campaign}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="idPoliticas" class="col-sm-4 col-form-label col-form-label-sm">Políticas y Cond.</label>
                <div class="col-sm-8">
                  <div class="input-group row m-0">
                    <select class="form-control form-control-sm" id="idPoliticas" formControlName="idPoliticas">
                      <option *ngFor="let polices of listPoliciesConditions" value="{{polices.id}}">{{polices.id}}-{{polices.name}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <button class="btn btn-secondary btn-sm" type="button" id="button-addon2" data-toggle="tooltip"
                        data-placement="bottom" title="VER POLÍTICAS Y CONDICIONES SELECCIONADO"
                        (click)="openModalFormPoliciesConditions(rgRegistry.getRawValue().idPoliticas)">
                        <i class="far fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="idBaseConcurso" class="col-sm-4 col-form-label col-form-label-sm">Bases del concurso</label>
                <div class="col-sm-8">
                  <div class="input-group row m-0">
                    <select class="form-control form-control-sm" id="idBaseConcurso" formControlName="idBaseConcurso">
                      <option *ngFor="let rules of listCompetitionRules" value="{{rules.id}}">{{rules.id}}-{{rules.name}}</option>
                    </select>
                    <div class="input-group-append">
                      <button class="btn btn-secondary btn-sm" type="button" id="button-addon2" data-toggle="tooltip"
                        data-placement="bottom" title="VER BASES DEL CONCURSO SELECCIONADO"
                        (click)="openModalFormBaseConcurso(this.rgRegistry.getRawValue().idBaseConcurso)">
                        <i class="far fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="idClasPromo" class="col-sm-4 col-form-label col-form-label-sm">Tipo</label>
                <div class="col-sm-8">
                  <select class="form-control form-control-sm" id="idClasPromo" formControlName="idClasPromo">
                    <option *ngFor="let cls of listTiposPromos" value="{{cls.id}}">{{cls.id}}-{{cls.nameCls}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Nombre</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control form-control-sm" id="name" formControlName="name" maxlength="20">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Título</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control form-control-sm" id="name" formControlName="titlePromo" maxlength="50">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="fechaIni" class="col-sm-4 col-form-label col-form-label-sm">Fecha Ini</label>
                <div class="col-sm-8">
                  <input type="date" class="form-control form-control-sm" id="fechaIni" formControlName="fechaIni">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between align-items-center mb-2">
            <div class="col-12">
              <div class="form-group row m-0">
                <label for="fechaFin" class="col-sm-4 col-form-label col-form-label-sm">Fecha Fin</label>
                <div class="col-sm-8">
                  <input type="date" class="form-control form-control-sm" id="fechaFin" formControlName="fechaFin">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link btn_link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">GRANDE</button>
              <button class="nav-link btn_link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">PEQUEÑO</button>
              <button class="nav-link btn_link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">FONDO</button>
              <button class="nav-link btn_link" id="nav-banner-tab" data-toggle="tab" data-target="#nav-banner" type="button" role="tab" aria-controls="nav-banner" aria-selected="false">BANNER</button>
              <button class="nav-link btn_link" id="nav-descrip-tab" data-toggle="tab" data-target="#nav-descrip" type="button" role="tab" aria-controls="nav-descrip" aria-selected="false"><i class="fas fa-align-justify"></i></button>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active mt-4" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm">Imagen 01</label>
                    <div class="col-sm-9">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input form-control-sm" id="customFile" accept="image/*"
                          (change)="onSelectFileImgGrande($event)">
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <div class="img_content_modal">
                      <img [src]="rutaImgGrandePromo" alt="{{rutaImgGrandePromo}}" onerror="this.onerror=null;this.src='assets/img/no-img.png'">
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="tab-pane fade mt-4" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm">Imagen 02</label>
                    <div class="col-sm-9">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input form-control-sm" id="customFile" accept="image/*"
                          (change)="onSelectFileImgMedium($event)">
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <div class="img_content_modal">
                      <img [src]="rutaImgMediumPromo" alt="{{rutaImgMediumPromo}}" onerror="this.onerror=null;this.src='assets/img/no-img.png'">
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="tab-pane fade mt-4" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm">Imagen 03</label>
                    <div class="col-sm-9">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input form-control-sm" id="customFile" accept="image/*"
                          (change)="onSelectFileImgBackG($event)">
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <div class="img_content_modal">
                      <img [src]="rutaImgBackgroundPromo" alt="{{rutaImgBackgroundPromo}}" onerror="this.onerror=null;this.src='assets/img/no-img.png'">
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="tab-pane fade mt-4" id="nav-banner" role="tabpanel" aria-labelledby="nav-banner-tab">
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm">Imagen 04</label>
                    <div class="col-sm-9">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input form-control-sm" id="customFile" accept="image/*"
                          (change)="onSelectFileImgBanner($event)">
                        <label class="custom-file-label" for="customFile">Choose file</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                  <div class="form-group row m-0">
                    <div class="img_content_modal">
                      <img [src]="rutaImgBannerPromo" alt="{{rutaImgBannerPromo}}" onerror="this.onerror=null;this.src='assets/img/no-img.png'">
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="tab-pane fade mt-4" id="nav-descrip" role="tabpanel" aria-labelledby="nav-descrip-tab">
              <div class="form-group row m-0">
                <!-- <label for="obse" class="col-sm-3 col-form-label col-form-label-sm">Descripción</label> -->
                <div class="col-sm-12">
                  <textarea class="form-control" id="descrip" rows="8" formControlName="descrip" maxlength="500" placeholder="Ingrese una breve descripción de la promoción"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer myModal-footer">
      <button type="submit" class="btn btn-ok btn-sm">
        <i class="far fa-save mr-1"></i> GUARDAR
      </button>
      <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
        <i class="fas fa-times mr-1"></i> CERRAR
      </button>
    </div>
  </form>
</ng-template>

<!---MODAL DE POLÍTICAS Y CONDICIONES --->
<ng-template #ModalFormPC let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="fas fa-lock mr-1"></i> POLÍTICAS Y CONDICIONES
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="m-0" [innerHTML]="textHtmlPolicies"></div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE BASES DEL CONCURSO --->
<ng-template #ModalFormBC let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-file-alt mr-1"></i> BASES DEL CONCURSO
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="m-0" [innerHTML]="textHtmlRules"></div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE IMAGEN GRANDE --->
<ng-template #ModalViewImgPromo let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="fas fa-lock mr-1"></i> PROMO: {{titlePromo}}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link btn_link active" id="nav-grande-tab" data-toggle="tab" data-target="#nav-grande" type="button" role="tab" aria-controls="nav-grande" aria-selected="true">GRANDE</button>
        <button class="nav-link btn_link" id="nav-medium-tab" data-toggle="tab" data-target="#nav-medium" type="button" role="tab" aria-controls="nav-medium" aria-selected="false">PEQUEÑO</button>
        <button class="nav-link btn_link" id="nav-fondo-tab" data-toggle="tab" data-target="#nav-fondo" type="button" role="tab" aria-controls="nav-fondo" aria-selected="false">FONDO</button>
        <button class="nav-link btn_link" id="nav-banner-tab" data-toggle="tab" data-target="#nav-banner" type="button" role="tab" aria-controls="nav-banner" aria-selected="false">BANNER</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active mt-4" id="nav-grande" role="tabpanel" aria-labelledby="nav-grande-tab">
        <div class="modal_box_img_promo">
          <img [src]="_toolsService.linkPublicImgGrangePromo(rutaImgGrandePromo)" alt="{{rutaImgGrandePromo}}"
            style="width: 85%; height: 80%;">
        </div>
      </div>
      <div class="tab-pane fade mt-4" id="nav-medium" role="tabpanel" aria-labelledby="nav-medium-tab">
        <div class="modal_box_img_promo">
          <img [src]="_toolsService.linkPublicImgMediumPromo(rutaImgMediumPromo)" alt="{{rutaImgMediumPromo}}"
            style="width: 85%; height: 80%;">
        </div>
      </div>
      <div class="tab-pane fade mt-4" id="nav-fondo" role="tabpanel" aria-labelledby="nav-fondo-tab">
        <div class="modal_box_img_promo">
          <img [src]="_toolsService.linkPublicImgBackgroundPromo(rutaImgBackgroundPromo)" alt="{{rutaImgBackgroundPromo}}"
            style="width: 85%; height: 80%;">
        </div>
      </div>
      <div class="tab-pane fade mt-4" id="nav-banner" role="tabpanel" aria-labelledby="nav-banner-tab">
        <div class="modal_box_img_promo">
          <img [src]="_toolsService.linkPublicImgBannerPromo(rutaImgBannerPromo)" alt="{{rutaImgBannerPromo}}"
            style="width: 85%; height: 80%;">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE MODAL LINK --->
<ng-template #ModalGetLink let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="fas fa-lock mr-1"></i> OBTENER LINK DE PÁGINA
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="">
      <p class="form_p mb-4">
        El presente link es el que se tiene que enviar a los clientes o difundirlo por todas las redes sociales para que
        los cliente puedan acceder, registrar sus datos y sobre todo participar del sorteo, el sorteo inicia el
        <b>{{objPromo.fechaIni | date:'dd/MM/yyyy' }}</b> y finaliza el <b>{{objPromo.fechaFin | date:'dd/MM/yyyy'}}</b>
      </p>
      <div class="input-group mb-2">
        <input type="text" class="form-control" placeholder="..." aria-label="inputLinkWeb"
          aria-describedby="inputlinkWeb" [(ngModel)]="inputLinkWeb" name="inputLinkWeb" id="inputLinkWeb" readonly>
        <div class="input-group-append">
          <button class="btn btn-secondary" type="button" id="goLinkCopy" data-toggle="tooltip" data-placement="bottom"
            title="CLICK PARA COPIAR LINK" (click)="copyLink(inputLinkWeb)">
            <i class="far fa-copy"></i>
          </button>
          <a class="btn btn-success" type="button" id="goLink" [href]="inputLinkWeb" target="_blank"
            data-toggle="tooltip" data-placement="bottom" title="CLICK PARA ABRIR EN GOOGLE">
            <i class="fab fa-google"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE VISTA DE PAGE PROMOTION--->
<ng-template #ModalPagePromoLink let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="fas fa-lock mr-1"></i> PROMO: {{titlePromo}}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <app-web-page-promo></app-web-page-promo>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>