<div class="content-header">
    <div class="container-fluid">
        <div class="row animate__animated animate__fadeIn">
            <div class="col-sm-6">
                <h1 class="m-0 title_windows"><b>BIENVENIDO</b></h1>
            </div>
        </div>
        <div class="row mb-1 animate__animated animate__fadeIn">
            <div class="col-sm-6">
                <h5 class="m-0 title_windows">MIDAS SOLUTIONS CENTER</h5>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item title_windows">{{dateNow}}</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>{{nroCampaign}}</h3>
                        <p>Campañas</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-calendar"></i>
                    </div>
                    <a [routerLink]="['/entities']" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{nroPromos}}</h3>
                        <p>Promociones</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <a [routerLink]="['/promotions']" class="small-box-footer">Ver más <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{nroClients}}</h3>
                        <p>Clientes</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a [routerLink]="['/client']" class="small-box-footer">ver más <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{nroSendSMS}}</h3>
                        <p>SMS Enviados</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-sms"></i>
                    </div>
                    <a [routerLink]="['/client']" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

        </div>
    </div>
</section>