import { MyFile, ResponseQueryGeneral, ResponseQuerySponsor, SessionUserLogger, Sponsor } from '@/utils';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { ConfigurationService } from '@services/config.service';
import { SponsorService } from '@services/sponsor.service';
import { ApiWebService } from '@services/api-web.service';
import { ToolsService } from '@services/tools.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  rutaImgSponsor: any;
  nroPathImg: number = 2;
  nameItem: string;
  rgRegistry: FormGroup;
  dataUserLogger: SessionUserLogger;
  listSponsors: Sponsor[] = [];
  fileIMG: MyFile = new MyFile();
  currentDay: any = DateTime.now().toFormat('y-LL-d');
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toolsService: ToolsService,
    private _dataApiSession: ApiWebService,
    private _sponsorService: SponsorService,
    private _dataConfig: ConfigurationService,
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    // this.changeComboBox();
  }

  // private changeComboBox() {
  //   this.rgRegistry.get('fechaIni').valueChanges.subscribe((fechaIni: any) => {
  //     let end: any = DateTime.fromISO(this.currentDay);
  //     let start: any = DateTime.fromISO(fechaIni);
  //     let diffInDay: any = start.diff(end, 'days');

  //     if (diffInDay.toObject().days <= -1) {
  //       this._mf.showToastrWarning('VALIDACIÓN', `La fecha inicial (${fechaIni}) no puede ver una fecha antes de la fecha actual (${this.currentDay}), , se ha ingresado automáticamente la fecha actual, Por favor verifique e ingrese la fecha correcta.`);
  //       this.rgRegistry.patchValue({
  //         fechaIni: this.currentDay
  //       })
  //       return;
  //     }
  //   })

  //   this.rgRegistry.get('fechaFin').valueChanges.subscribe((fechaFin: any) => {
  //     let fechaIni: any = this.rgRegistry.get('fechaIni').value;      
  //     if (fechaIni == null) {
  //       this._mf.showToastrWarning('VALIDACIÓN', `Debe primero ingresar la FECHA INICIAL de la promoción.`);
  //       return;
  //     }

  //     let start: any = DateTime.fromISO(fechaFin);
  //     let end: any = DateTime.fromISO(fechaIni);
  //     let diffInDay: any = end.diff(start, 'days');

  //     if (diffInDay.toObject().days >= 1) {
  //       this._mf.showToastrWarning('VALIDACIÓN', `La fecha final (${fechaFin}) no puede ver una fecha antes de la fecha inicial (${fechaIni}), se ha ingresado automáticamente la fecha inicial, Por favor verifique e ingrese la fecha correcta.`);
  //       this.rgRegistry.patchValue({
  //         fechaFin: fechaIni
  //       })
  //       return;
  //     }
  //   })
  // }


  private loadForms() {
    const model = new Sponsor();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: Sponsor): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      sponsor: new FormControl(
        {
          value: model.sponsor,
          disabled: false
        },
        Validators.required
      ),
      contact: new FormControl(
        {
          value: model.contact,
          disabled: false
        },
        Validators.required
      ),
      codPostal: new FormControl(
        {
          value: model.codPostal,
          disabled: false
        },
        Validators.required
      ),
      movil: new FormControl(
        {
          value: model.movil,
          disabled: false
        },
        Validators.required
      ),
      // fechaIni: new FormControl(
      //   {
      //     value: model.fechaIni,
      //     disabled: false
      //   },
      //   Validators.required
      // ),
      // fechaFin: new FormControl(
      //   {
      //     value: model.fechaFin,
      //     disabled: false
      //   },
      //   Validators.required
      // ),
      pathImgLogo: new FormControl(
        {
          value: model.pathImgLogo,
          disabled: false
        },
        Validators.required
      )
    }
  }

  public listarRegistros() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listSponsors = [];
    return this._sponsorService.getAll().subscribe((result: ResponseQuerySponsor) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listSponsors = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public openModalFormEdit(typeForm: string, sponsor: Sponsor = null) {
    this.setDataFormEdit(typeForm, sponsor);
    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, sponsor: Sponsor) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        this.rutaImgSponsor = '';
        let tmpSponsor: Sponsor = new Sponsor();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpSponsor,
          fechaReg: DateTime.now(),
          userReg: Number(this.dataUserLogger.user.id),
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rutaImgSponsor = this._toolsService.linkPublicLogoSponsor(sponsor.pathImgLogo);
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...sponsor,
          id: Number(sponsor.id),
          status: (sponsor.status == 1) ? true : false
        })
        break;

      default:
        break;
    }
  }

  public onSelectFile(event: any) {
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      this.rgRegistry.patchValue({ pathImgLogo: file.name });
      var reader_preview = new FileReader();
      reader_preview.readAsDataURL(file); // read file as data url
      reader_preview.onload = (event) => { // called once readAsDataURL is completed
        this.rutaImgSponsor = event.target.result;

        this.fileIMG.namefile = file.name;
        this.fileIMG.typefile = file.type;
        this.fileIMG.option = this.nroPathImg;

        var reader_upload = new FileReader();
        reader_upload.onload = this._handleReaderLoadedImage.bind(this);
        reader_upload.readAsBinaryString(file);
      }
    }
  }

  private _handleReaderLoadedImage(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.fileIMG.base64tex = btoa(binaryString);
  }

  public openModalFormDelete(data: Sponsor) {
    this.nameItem = data.sponsor;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => {
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: Sponsor = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    if (dataForm.id == 0) {
      if (this.fileIMG === undefined) {
        this._mf.showToastrWarning('VALIDACIÓN', 'Aun no ha seleccionado una imagen, por favor seleccione una para realizar la actualización o subirla al servidor');
        return;
      }
      this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'new'));
    } else {
      if (this.fileIMG.namefile !== undefined || this.fileIMG.namefile !== undefined) {
        this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'edit'));
      } else {
        this.subscriptions.add(this.postEditData(dataForm));
      }
    }
  }

  private uploadSrvImg(image: MyFile, dataForm: Sponsor, typOP: string): Subscription {
    return this._toolsService.img_upload(image).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'La imagen se subió satisfactoriamente');
        switch (typOP) {
          case 'new':
            this.subscriptions.add(this.postSaveData(dataForm));
            break;

          case 'edit':
            this.subscriptions.add(this.postEditData(dataForm));
            break;

          default:
            break;
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
    })
  }

  private postSaveData(data: Sponsor): Subscription {
    return this._sponsorService.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: Sponsor): Subscription {
    return this._sponsorService.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: Sponsor): Subscription {
    let img_old = { namefile: data.pathImgLogo, option: 2 };
    return this._toolsService.img_delete(img_old).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._sponsorService.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
          if (result.response) {
            this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
            this.subscriptions.add(this.getAllData());
            this.modalService.dismissAll();
          } else {
            this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
          }
        }, err => {
          this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
          if (err.error.CodeErr == 401) {
            this._dataApiSession.logout();
            this.route.navigate(['/login']);
          }
        })
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
    })
  }

}
