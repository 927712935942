import { Collaborator, ResponseQueryCollaborator, ResponseQueryGeneral, SessionUserLogger } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiWebService } from '@services/api-web.service';
import { CollaboratorService } from '@services/collaborator.service';
import { ConfigurationService } from '@services/config.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DateTime } from "luxon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  nameItem: string;
  rgRegistry: FormGroup;
  public dataUserLogger: SessionUserLogger;
  listCollaborator: Collaborator[] = [];
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _dataApiSession: ApiWebService,
    private _dataConfig: ConfigurationService,
    private _collaboratorSvr: CollaboratorService
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
  }

  private loadForms() {
    const model = new Collaborator();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: Collaborator): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      name: new FormControl(
        {
          value: model.name,
          disabled: false
        },
        [Validators.required]
      ),
      idTypeDoc: new FormControl(
        {
          value: model.idTypeDoc, 
          disabled: false
        },
        Validators.required
      ),
      nroDoc: new FormControl(
        {
          value: model.nroDoc, 
          disabled: false
        },
        Validators.required
      ),
      email: new FormControl(
        {
          value: model.email, 
          disabled: false
        },
        [Validators.required,
        Validators.email]
      ),
      phone: new FormControl(
        {
          value: model.phone, 
          disabled: false
        },
        Validators.required
      ),
      area: new FormControl(
        {
          value: model.area, 
          disabled: false
        },
        Validators.required
      )
    }
  }

  public listRecords(){
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listCollaborator = [];
    return this._collaboratorSvr.getAll().subscribe((result: ResponseQueryCollaborator) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCollaborator = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public openModalFormEdit(typeForm: string, collaborator: Collaborator = null) {
    this.setDataFormEdit(typeForm, collaborator);
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, collaborator: Collaborator = null) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        let tmpCollaborator: Collaborator = new Collaborator();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpCollaborator,
          fechaReg: DateTime.now(),
          userReg: this.dataUserLogger.user.id,
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...collaborator,
          status: (collaborator.status == 1) ? true : false
        })
        break;

      default:
        break;
    }
  }

  public openModalFormDelete(collaborator: Collaborator) {
    this.nameItem = collaborator.name;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => {  this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { 
        this.subscriptions.add(this.deleteData(collaborator));
      }
    )
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const data: Collaborator = this.rgRegistry.getRawValue();
    data.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    if(data.id == 0){
      this.subscriptions.add(this.postSaveData(data));
    }else{
      this.subscriptions.add(this.postEditData(data));
    }
  }

  private postSaveData(data: Collaborator):Subscription{
    return this._collaboratorSvr.register(data).subscribe((result: ResponseQueryGeneral)=>{
      if(result.response){
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      }else{
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: Collaborator):Subscription{
    return this._collaboratorSvr.update(data).subscribe((result: ResponseQueryGeneral)=>{
      if(result.response){
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      }else{
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: Collaborator):Subscription{
    return this._collaboratorSvr.delete(data.id).subscribe((result: ResponseQueryGeneral)=>{
      if(result.response){
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      }else{
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

}
