<pf-dropdown hide-arrow class="user-menu">
    <div slot="button">
        <a class="nav-link">
            <i class="far fa-user-circle" style="font-size: 23px;"></i>
            <!-- <pf-image
                [src]="pathIgUser"
                fallback-src="assets/img/default-profile.png"
                class="user-image-small"
                width="25"
                height="25"
                alt="User Image"
                rounded
            ></pf-image> -->
        </a>
    </div>
    <div slot="menu">
        <li class="user-header bg-primary">
            <pf-image
                [src]="pathIgUser"
                fallback-src="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded
            ></pf-image>
            <p>
                <span>{{ data?.user?.name }}</span>
                <small>
                    <span class="mr-2">Miembro desde</span><br>
                    <span>{{ data?.user?.fechaReg }}</span>
                    <!-- <span>{{ formatDate(user?.user?.fechaReg) }}</span> -->
                </small>
            </p>
        </li>

        <!-- Menu Body -->
        <!-- <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
        </li> -->

        <!-- Menu Footer-->
        <li class="user-footer">
            <!-- <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Mi perfil
            </a> -->
            <a (click)="logout()" class="btn btn-default btn-flat float-right">
                Cerrar Sesión
            </a>
        </li>
    </div>
</pf-dropdown>
