import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/setting/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';

import { EntitiesComponent } from '@pages/setting/entities/entities.component';
import { AccessSystemComponent } from '@pages/setting/access-system/access-system.component';
import { ClientsComponent } from '@pages/clients/clients.component';
import { PromotionsComponent } from '@pages/promotions/promotions.component';
import { WebPagePromoComponent } from '@pages/web-page-promo/web-page-promo.component';
import { AuditSystemComponent } from '@pages/setting/audit-system/audit-system.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'client',
                component: ClientsComponent
            },
            {
                path: 'Access',
                component: AccessSystemComponent
            },
            {
                path: 'entities',
                component: EntitiesComponent
            },
            {
                path: 'promotions',
                component: PromotionsComponent
            },
            {
                path: 'audit-system',
                component: AuditSystemComponent
            }
        ]
    },
    {
        path: 'page/:namePromo',
        component: WebPagePromoComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    // {
    //     path: 'register',
    //     component: RegisterComponent,
    //     canActivate: [NonAuthGuard]
    // },
    // {
    //     path: 'forgot-password',
    //     component: ForgotPasswordComponent,
    //     canActivate: [NonAuthGuard]
    // },
    // {
    //     path: 'recover-password',
    //     component: RecoverPasswordComponent,
    //     canActivate: [NonAuthGuard]
    // },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
