import { map } from 'lodash';
import { Model } from '../model';

export class UserDTO extends Model {
    public id: number;
    public status: number;
    public idCollaborator: number;
    public nameCollaborator: string;
    public emailCollaborator: string;
    public nroDocCollaborator: string;
    public movilCollaborator: string;
    public idTypeUser: number;
    public nameTypeUser: string;
    public nickUser: string;
    public passUser: string;
    public valData: number;
    public fechaReg: any;
    public userReg: number;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCollaborator = this.idCollaborator || 0;
        this.nameCollaborator = this.nameCollaborator || '';
        this.emailCollaborator = this.emailCollaborator || '';
        this.nroDocCollaborator = this.nroDocCollaborator || '';
        this.movilCollaborator = this.movilCollaborator || '';
        this.idTypeUser = this.idTypeUser || 4;
        this.nameTypeUser = this.nameTypeUser || '';
        this.nickUser = this.nickUser || '';
        this.passUser = this.passUser || '';
        this.passUser = this.passUser || '';
        this.valData = this.valData || 0;
        this.fechaReg = this.fechaReg || '';
        this.userReg = this.userReg || 0;
    }

    public static cast(data: object): UserDTO {
        return new UserDTO(data);
    }

    public static casts(data: object[]): UserDTO[] {
        return map(data, c => new UserDTO(c));
    }
}