import { map } from 'lodash';
import { Model } from '../model';
import { Auditoria, ClassificationPromo, Collaborator, logSendSmsDTO, Promotion, SessionUserLogger, Sponsor, TextTypeSMS, User } from '../models';
import { UserDTO } from './AccessDTO.model';
import { CampaignDTO, CompetitionRulesDTO, LegalNoticesDTO, PoliciesConditionsDTO, TemplateSmsDTO } from './entitiesDTO.mode';
import { ClientDTO } from './promoDTO.model';

export class ResponseQueryGeneral extends Model {
    public response: boolean;
    public message: string;
    public result: any;
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || '';
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryGeneral {
        return new ResponseQueryGeneral(data);
    }

    public static casts(data: object[]): ResponseQueryGeneral[] {
        return map(data, c => new ResponseQueryGeneral(c));
    }
}

export class ResponseQueryClient extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: ClientDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryClient {
        return new ResponseQueryClient(data);
    }

    public static casts(data: object[]): ResponseQueryClient[] {
        return map(data, c => new ResponseQueryClient(c));
    }
}

export class ResponseQueryPromo extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: Promotion[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryPromo {
        return new ResponseQueryPromo(data);
    }

    public static casts(data: object[]): ResponseQueryPromo[] {
        return map(data, c => new ResponseQueryPromo(c));
    }
}

export class ResponseQueryClsPromo extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: ClassificationPromo[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryClsPromo {
        return new ResponseQueryClsPromo(data);
    }

    public static casts(data: object[]): ResponseQueryClsPromo[] {
        return map(data, c => new ResponseQueryClsPromo(c));
    }
}

export class ResponseQueryCollaborator extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: Collaborator[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryCollaborator {
        return new ResponseQueryCollaborator(data);
    }

    public static casts(data: object[]): ResponseQueryCollaborator[] {
        return map(data, c => new ResponseQueryCollaborator(c));
    }
}

export class ResponseQueryUser extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: UserDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryUser {
        return new ResponseQueryUser(data);
    }

    public static casts(data: object[]): ResponseQueryUser[] {
        return map(data, c => new ResponseQueryUser(c));
    }
}

export class ResponseQuerySponsor extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: Sponsor[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQuerySponsor {
        return new ResponseQuerySponsor(data);
    }

    public static casts(data: object[]): ResponseQuerySponsor[] {
        return map(data, c => new ResponseQuerySponsor(c));
    }
}

export class ResponseQueryCampaign extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: CampaignDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryCampaign {
        return new ResponseQueryCampaign(data);
    }

    public static casts(data: object[]): ResponseQueryCampaign[] {
        return map(data, c => new ResponseQueryCampaign(c));
    }
}

export class ResponseQueryTextTypeSMS extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: TextTypeSMS[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryTextTypeSMS {
        return new ResponseQueryTextTypeSMS(data);
    }

    public static casts(data: object[]): ResponseQueryTextTypeSMS[] {
        return map(data, c => new ResponseQueryTextTypeSMS(c));
    }
}

export class ResponseQueryTemplateSms extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: TemplateSmsDTO[];
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryTemplateSms {
        return new ResponseQueryTemplateSms(data);
    }

    public static casts(data: object[]): ResponseQueryTemplateSms[] {
        return map(data, c => new ResponseQueryTemplateSms(c));
    }
}

export class ResponseQueryPoliciesConditions extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: PoliciesConditionsDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryPoliciesConditions {
        return new ResponseQueryPoliciesConditions(data);
    }

    public static casts(data: object[]): ResponseQueryPoliciesConditions[] {
        return map(data, c => new ResponseQueryPoliciesConditions(c));
    }
}

export class ResponseQueryLegalNotices extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: LegalNoticesDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryLegalNotices {
        return new ResponseQueryLegalNotices(data);
    }

    public static casts(data: object[]): ResponseQueryLegalNotices[] {
        return map(data, c => new ResponseQueryLegalNotices(c));
    }
}

export class ResponseQueryCompetitionRules extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: CompetitionRulesDTO[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryCompetitionRules {
        return new ResponseQueryCompetitionRules(data);
    }

    public static casts(data: object[]): ResponseQueryCompetitionRules[] {
        return map(data, c => new ResponseQueryCompetitionRules(c));
    }
}

export class ResponseQueryAuditoria extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: Auditoria[],
        total: number
    };
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryAuditoria {
        return new ResponseQueryAuditoria(data);
    }

    public static casts(data: object[]): ResponseQueryAuditoria[] {
        return map(data, c => new ResponseQueryAuditoria(c));
    }
}

export class ResponseQueryLogSms extends Model {
    public response: boolean;
    public message: string;
    public result: {
        data: logSendSmsDTO[],
        total: number
    }
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseQueryLogSms {
        return new ResponseQueryLogSms(data);
    }

    public static casts(data: object[]): ResponseQueryLogSms[] {
        return map(data, c => new ResponseQueryLogSms(c));
    }
}

export class ResponseSessionUser extends Model {
    public response: boolean;
    public message: string;
    public result: SessionUserLogger;
    public errors: any;

    constructor(data?: object) {
        super(data);
        this.response = this.response || false;
        this.message = this.message || 'Ocurrió un error inesperado.';
        this.result = this.result || null;
        this.errors = this.errors || '';
    }

    public static cast(data: object): ResponseSessionUser {
        return new ResponseSessionUser(data);
    }

    public static casts(data: object[]): ResponseSessionUser[] {
        return map(data, c => new ResponseSessionUser(c));
    }
}