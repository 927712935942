import { Component, OnInit, OnDestroy, Renderer2, HostBinding } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { LoggerUser, ResponseQueryGeneral, ResponseSessionUser, SessionUserLogger } from '@/utils';
import { ApiWebService } from '@services/api-web.service';
import { ToolsService } from '@services/tools.service';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    // @HostBinding('class') class = 'login-box';

    public loginForm: FormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    LoggerUser: LoggerUser = new LoggerUser();
    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private _auth: AuthService,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private _mf: MyMethodsService,
        private appService: AppService,
        private _formBuilder: FormBuilder,
        private _toolsService: ToolsService,
        private _dataApiSession: ApiWebService
    ) {
        this.loadForms();
    }

    // developer@gmail.com --> angel30
    ngOnInit() {
        // this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    }

    ngOnDestroy() {
        // this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
        this.subscriptions.unsubscribe();
    }

    private loadForms() {
        const model = new LoggerUser();
        const formGroupData = this.getFormGroupData(model);
        this.loginForm = this._formBuilder.group(formGroupData);
    }

    private getFormGroupData(model: LoggerUser): object {
        return {
            ...this._mf.modelToFormGroupData(model),
            nickUser: new FormControl(
                {
                    value: model.nickUser, // developer
                    disabled: false
                },
                Validators.required
            ),
            passUser: new FormControl(
                {
                    value: model.nickUser, // admin123
                    disabled: false
                },
                Validators.required
            )
        }
    }

    public loginByAuth() {
        if (this.loginForm.invalid) {
            this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
            return;
        }

        if (String(this.loginForm.getRawValue().nickUser).length == 0) {
            this._mf.showToastrWarning('VALIDACIÓN', 'No se ha ingresado el usuario');
            return;
        }

        if (String(this.loginForm.getRawValue().passUser).length == 0) {
            this._mf.showToastrWarning('VALIDACIÓN', 'No se a ingresado una contraseña');
            return;
        }
        this.LoggerUser = { nickUser: this.loginForm.getRawValue().nickUser, passUser: this.loginForm.getRawValue().passUser }
        this.subscriptions.add(this.validLoggerUser(this.LoggerUser));
    }

    private validLoggerUser(data: LoggerUser) {
        this._auth.logIn(data).subscribe((response: ResponseSessionUser) => {
            if (response.response) {
                this._mf.showToastrSuccess('CREDENCIALES VALIDADAS', `Bienvenido al sistema ${response?.result?.collaborator?.name}`);
                this.setDataAPI(response?.result);
                setTimeout(() => {
                    this.router.navigate(['/']);
                }, 1000);
            } else {
                this._mf.showToastrWarning('CREDENCIALES INVALIDADAS', 'Las credenciales ingresadas son incorrectas por favor ingrese sus credenciales correctas o puede comunicarse con el administrador del sistema');
            }
        }, err => {
            this._mf.showToastrWarning('VALIDACIÓN', JSON.stringify(err.error));
        })
    }

    private setDataAPI(data: SessionUserLogger) {
        let dayNow: any = DateTime.now().toFormat('dd-LL-y');
        localStorage.setItem(`_t${dayNow}`, window.btoa(unescape(encodeURIComponent(data.token))));
        localStorage.setItem(`_u${dayNow}`, window.btoa(unescape(encodeURIComponent(JSON.stringify(data)))));

        this._dataApiSession.setIsTokenWeb(data.token);
        this._dataApiSession.setIsLoadUser(true);
        this._dataApiSession.setIsDataUser(data)

        this._toolsService.dataCompanyWeb().subscribe((result: ResponseQueryGeneral) => {
            if (result.response) {
                this._dataApiSession.setIsDataWeb(result?.result)
                localStorage.setItem(`_w${dayNow}`, window.btoa(unescape(encodeURIComponent(JSON.stringify(result?.result)))));
            } else {
                console.log(result.errors);
            }
        }, err => {
            console.log(err);
        })

    }


}
