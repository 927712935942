import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ApiWebService } from './api-web.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private headers: HttpHeaders;
    private subscriptions: Subscription = new Subscription();
    private authToken: string;

    constructor(
        private http: HttpClient,
        private webapi: ApiWebService
    ) {

    }

    public get requestOptions(): { headers: HttpHeaders } {
        this.subscriptions.add(
            this.webapi.getIsTokenWeb().subscribe(
                (token: string) => (this.authToken = token)
            )
        );
        const authTokenWeb = this.authToken;
        const headers: HttpHeaders = new HttpHeaders().set('APP-TOKEN', `${authTokenWeb}`);
        return {
            headers: headers
        };
    }

}
