import { Campaign, CampaignDTO, ResponseQueryCampaign, ResponseQueryGeneral, ResponseQuerySponsor, SessionUserLogger, Sponsor } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiWebService } from '@services/api-web.service';
import { CampaignService } from '@services/campaign.service';
import { ConfigurationService } from '@services/config.service';
import { SponsorService } from '@services/sponsor.service';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  nameItem: string;
  rgRegistry: FormGroup;
  listSponsors: Sponsor[] = [];
  listCampaigns: CampaignDTO[] = [];
  dataUserLogger: SessionUserLogger;
  currentDay: any = DateTime.now().toFormat('y-LL-d');
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _dataApiSession: ApiWebService,
    private _sponsorService: SponsorService,
    private _campaignService: CampaignService,
    private _dataConfig: ConfigurationService
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    this.subscriptions.add(this.getAllDataSponsors());
    this.changeComboBox();
  }

  private changeComboBox() {
    if (this.listSponsors.length > 0) {
      this.rgRegistry.get('idCollaborator').valueChanges.subscribe((value: any) => {
        let data: Sponsor = this.listSponsors.find(x => x.id == value);
        this.rgRegistry.patchValue({
          nameSponsor: data.sponsor
        })
      })
    }

    this.rgRegistry.get('fechaIni').valueChanges.subscribe((fechaIni: any) => {
      let end: any = DateTime.fromISO(this.currentDay);
      let start: any = DateTime.fromISO(fechaIni);
      let diffInDay: any = start.diff(end, 'days');

      if (diffInDay.toObject().days <= -1) {
        this._mf.showToastrWarning('VALIDACIÓN', `La fecha inicial (${fechaIni}) no puede ver una fecha antes de la fecha actual (${this.currentDay}), , se ha ingresado automáticamente la fecha actual, Por favor verifique e ingrese la fecha correcta.`);
        this.rgRegistry.patchValue({
          fechaIni: this.currentDay
        })
        return;
      }
    })

    this.rgRegistry.get('fechaFin').valueChanges.subscribe((fechaFin: any) => {
      let fechaIni: any = this.rgRegistry.get('fechaIni').value;      
      if (fechaIni == null) {
        this._mf.showToastrWarning('VALIDACIÓN', `Debe primero ingresar la FECHA INICIAL de la promoción.`);
        return;
      }

      let start: any = DateTime.fromISO(fechaFin);
      let end: any = DateTime.fromISO(fechaIni);
      let diffInDay: any = end.diff(start, 'days');

      if (diffInDay.toObject().days >= 1) {
        this._mf.showToastrWarning('VALIDACIÓN', `La fecha final (${fechaFin}) no puede ver una fecha antes de la fecha inicial (${fechaIni}), se ha ingresado automáticamente la fecha inicial, Por favor verifique e ingrese la fecha correcta.`);
        this.rgRegistry.patchValue({
          fechaFin: fechaIni
        })
        return;
      }
    })
  }


  private loadForms() {
    const model = new CampaignDTO();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: CampaignDTO): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      campaign: new FormControl(
        {
          value: model.campaign,
          disabled: false
        },
        Validators.required
      ),
      idSponsor: new FormControl(
        {
          value: model.idSponsor,
          disabled: false
        },
        Validators.required
      ),
      fechaReg: new FormControl(
        {
          value: model.fechaReg,
          disabled: true
        },
        Validators.required
      ),
      year: new FormControl(
        {
          value: model.year,
          disabled: true
        },
        Validators.required
      ),
      fechaIni: new FormControl(
        {
          value: model.fechaIni,
          disabled: false
        },
        Validators.required
      ),
      fechaFin: new FormControl(
        {
          value: model.fechaFin,
          disabled: false
        },
        Validators.required
      )
    }
  }

  public getAllDataSponsors(): Subscription {
    this.listSponsors = [];
    return this._sponsorService.getAll().subscribe((result: ResponseQuerySponsor) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listSponsors = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public listRecords() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listCampaigns = [];
    return this._campaignService.getAll().subscribe((result: ResponseQueryCampaign) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCampaigns = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public openModalFormEdit(typeForm: string, dataForm: CampaignDTO = null) {
    this.setDataFormEdit(typeForm, dataForm);
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, campaignDTO: CampaignDTO) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        let tmpUser: CampaignDTO = new CampaignDTO();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpUser,
          fechaReg: DateTime.now().toISODate(),
          year: DateTime.now().toFormat('y'),
          userReg: this.dataUserLogger?.user?.id
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...campaignDTO,
          status: (campaignDTO.status == 1) ? true : false,
          id: Number(campaignDTO.id),
          passUser: ''
        })
        break;

      default:
        break;        
    }
  }

  public openModalFormDelete(data: CampaignDTO) {
    this.nameItem = data.campaign;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { 
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }
  
  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: CampaignDTO = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    const campaign: Campaign = new Campaign({
      id: dataForm.id,
      status: dataForm.status,
      campaign: dataForm.campaign,
      idSponsor: Number(dataForm.idSponsor),
      year: Number(dataForm.year),
      obse: dataForm.obse,
      fechaIni:dataForm.fechaIni,
      fechaFin:dataForm.fechaFin,
      fechaReg: dataForm.fechaReg,
      userReg: dataForm.userReg
    })

    if(campaign.id == 0){
      this.subscriptions.add(this.postSaveData(campaign));
    }else{
      this.subscriptions.add(this.postEditData(campaign));
    }
  }

  private postSaveData(data: Campaign): Subscription {
    return this._campaignService.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: Campaign): Subscription {
    return this._campaignService.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: CampaignDTO): Subscription {
    return this._campaignService.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }


}
