import { map } from 'lodash';
import { Model } from '../model';

export class Auditoria extends Model {
    public id: number;
    public fecha: any;
    public idUser: number;
    public nickUser: string;
    public idTypeUser: number;
    public nameTypeUser: string;
    public idCollaborate: number;
    public nameCollaborate: string;
    public windows: string;
    public codigo: string;
    public operation: string;
    
    constructor(data?: object) {
        super(data);        
        this.id = this.id || 0;
        this.fecha = this.fecha || '';
        this.idUser = this.idUser || 0;
        this.nickUser = this.nickUser || '';
        this.idTypeUser = this.idTypeUser || 0;
        this.nameTypeUser = this.nameTypeUser || '';
        this.idCollaborate = this.idCollaborate || 0;
        this.nameCollaborate = this.nameCollaborate || '';
        this.windows = this.windows || '';
        this.codigo = this.codigo || '';
        this.operation = this.operation || '';

    }

    public static cast(data: object): Auditoria {
        return new Auditoria(data);
    }

    public static casts(data: object[]): Auditoria[] {
        return map(data, c => new Auditoria(c));
    }
}