import { map } from 'lodash';
import { Model } from '../model';

export class Sponsor extends Model {
    public id: number;
    public status: number;
    public sponsor: string;
    public contact: string;
    public codPostal: string;
    public movil: string;
    // public fechaIni: any;
    // public fechaFin: any;
    public obse: string;
    public pathImgLogo: string;
    public cssColorPrimary: string;
    public cssColorSecondary: string;
    public cssColorTerceary: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.sponsor = this.sponsor || '';
        this.contact = this.contact || '';
        this.codPostal = this.codPostal || '';
        this.movil = this.movil || '';
        // this.fechaIni = this.fechaIni || '';
        // this.fechaFin = this.fechaFin || '';
        this.obse = this.obse || '';
        this.pathImgLogo = this.pathImgLogo || '';
        this.cssColorPrimary = this.cssColorPrimary || '';
        this.cssColorSecondary = this.cssColorSecondary || '';
        this.cssColorTerceary = this.cssColorTerceary || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): Sponsor {
        return new Sponsor(data);
    }

    public static casts(data: object[]): Sponsor[] {
        return map(data, c => new Sponsor(c));
    }
}

export class Campaign extends Model {
    public id: number;
    public status: number;
    public campaign: string;
    public idSponsor: number;
    public year: number;
    public obse: string;
    public fechaIni: any;
    public fechaFin: any;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.campaign = this.campaign || '';
        this.idSponsor = this.idSponsor || 0;
        this.fechaReg = this.fechaReg || '';
        this.year = this.year || 0;
        this.obse = this.obse || '';
        this.fechaIni = this.fechaIni || '';
        this.fechaFin = this.fechaFin || '';
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): Campaign {
        return new Campaign(data);
    }

    public static casts(data: object[]): Campaign[] {
        return map(data, c => new Campaign(c));
    }
}

export class TextTypeSMS extends Model {
    public id: number;
    public status: number;
    public typeSms: string;
    public obse: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.typeSms = this.typeSms || '';
        this.obse = this.obse || '';
        this.fechaReg = this.fechaReg || '';
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): TextTypeSMS {
        return new TextTypeSMS(data);
    }

    public static casts(data: object[]): TextTypeSMS[] {
        return map(data, c => new TextTypeSMS(c));
    }
}

export class TemplateSMS extends Model {
    public id: number;
    public status: number;
    public idTypeSms: number;
    public name: string;
    public template: string;    
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idTypeSms = this.idTypeSms || 0;
        this.name = this.name || '';
        this.template = this.template || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): TemplateSMS {
        return new TemplateSMS(data);
    }

    public static casts(data: object[]): TemplateSMS[] {
        return map(data, c => new TemplateSMS(c));
    }
}

export class PoliciesConditions extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): PoliciesConditions {
        return new PoliciesConditions(data);
    }

    public static casts(data: object[]): PoliciesConditions[] {
        return map(data, c => new PoliciesConditions(c));
    }
}

export class LegalNotices extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): LegalNotices {
        return new LegalNotices(data);
    }

    public static casts(data: object[]): LegalNotices[] {
        return map(data, c => new LegalNotices(c));
    }
}

export class CompetitionRules extends Model {
    public id: number;
    public status: number;
    public idCampaign: number;
    public name: string;
    public textHtml: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCampaign = this.idCampaign || 0;
        this.name = this.name || '';
        this.textHtml = this.textHtml || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): CompetitionRules {
        return new CompetitionRules(data);
    }

    public static casts(data: object[]): CompetitionRules[] {
        return map(data, c => new CompetitionRules(c));
    }
}

export class MyFile extends Model {
	public namefile: string;
	public typefile: string;
	public option: number;
	public base64tex:any;

	constructor(data?: object) {
		super(data);
	}

	public static cast(data: object): MyFile {
		return new MyFile(data);
	}

	public static casts(data: object[]): MyFile[] {
		return map(data, u => new MyFile(u));
	}
}