import { IConfigSrv } from '@/utils/interfaces/IConfigSrv';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export type ConfigKey = keyof IConfigSrv;

export const TEST_CONFIG: IConfigSrv = {
    apiUrl: '',
    linkWebSorteo: '',
    toastr_timeout: 1000,
};

const WINDOW_CONFIG: any = window['SYS_ECOMMERCE_CONFIG'];

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    public result: IConfigSrv = _.clone(WINDOW_CONFIG || TEST_CONFIG);
    constructor(private http: HttpClient) { }
}
