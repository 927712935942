import { Collaborator, ResponseQueryCollaborator, ResponseQueryGeneral } from '@/utils';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CollaboratorService {

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrl(): string {
        return this.config.result.apiUrl + 'collaborator/';
    }

    public getAll(): Observable<ResponseQueryCollaborator> {
        const endpoint = `${this.baseUrl}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryCollaborator) => res));
    }

    public getDataByID(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public register(data: Collaborator): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public update(data: Collaborator): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public delete(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

}
