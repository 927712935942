import { map } from 'lodash';
import { Model } from '../model';

export class UserLoggerDTO extends Model {
    public nickUser: string;
    public passUser: string;

    constructor(data?: object) {
        super(data);
        this.nickUser = this.nickUser ||'';
        this.passUser = this.passUser || '';
    }

    public static cast(data: object): UserLoggerDTO {
        return new UserLoggerDTO(data);
    }

    public static casts(data: object[]): UserLoggerDTO[] {
        return map(data, c => new UserLoggerDTO(c));
    }
}

export class SessionUser extends Model {
    public id: number;
    public status: number;
    public idCollaborator: number;
    public nameCollaborator: string;
    public nroDocCollaborator: string;
    public movilCollaborator: string;
    public idTypeUser: number;
    public nameTypeUser: string;
    public token: string;

    constructor(data?: object) {
        super(data);
        this.status = this.status || 0;
        this.idCollaborator = this.idCollaborator || 0;
        this.nameCollaborator = this.nameCollaborator || '';
        this.nroDocCollaborator = this.nroDocCollaborator || '';
        this.movilCollaborator = this.movilCollaborator || '';
        this.idTypeUser = this.idTypeUser || 0;
        this.nameTypeUser = this.nameTypeUser || '';
        this.token = this.token || '';
    }

    public static cast(data: object): SessionUser {
        return new SessionUser(data);
    }

    public static casts(data: object[]): SessionUser[] {
        return map(data, c => new SessionUser(c));
    }
}