<section class="content-header animate__animated animate__fadeIn">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="title_windows"><b><i class="fas fa-user-tie mr-1"></i>Clientes</b></h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                    <li class="breadcrumb-item active">Clientes</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<section class="content animate__animated animate__fadeIn">
    <div class="container-fluid">
        <div class="card mb-2">
            <div class="card-body border_top_card p-2">
                <div class="row row_search_card">
                    <div class="col-sm-5">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                                    BUSCAR
                                </button>
                                <ul class="dropdown-menu">
                                    <li class="dropdown-item"><a>Nombre</a></li>
                                    <li class="dropdown-item"><a>Codigo</a></li>
                                    <li class="dropdown-item"><a>Documento</a></li>
                                </ul>
                            </div>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="row_btn_new">
                            <!-- <button type="button" class="btn btn-success btn-sm mr-1" data-toggle="tooltip"
                                data-placement="bottom" title="Nuevo registro" (click)="openModalFormEdit('new')"
                                *ngIf="dataUserLogger?.user?.idTypeUser == 1">
                                <i class="fas fa-plus mr-1"></i>
                                <span>NUEVO</span>
                            </button> -->
                            <!-- <button type="button" class="btn btn-success btn-sm mr-1" data-toggle="tooltip"
                                data-placement="bottom" title="Nuevo registro" (click)="openModalFormEdit('new')"
                                *ngIf="dataUserLogger?.user?.idTypeUser == 1">
                                <i class="fas fa-plus mr-1"></i>
                                <span>GANADORES</span>
                            </button> -->
                            <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip"
                                data-placement="bottom" title="Listar registros" (click)="listRecords()">
                                <i class="fas fa-sync-alt"></i>
                            </button>
                            <!-- <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip"
                                data-placement="bottom" title="Generar Reporte">
                                <i class="fas fa-cloud-download-alt"></i>
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body table-responsive p-0" style="height: 400px;">
                <table class="table table-hover table-striped table-head-fixed table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th style="width:80px">ID</th>
                            <th style="width:50px">ESTADO</th>
                            <th style="width:420px">NOMBRE y APELLIDOS</th>
                            <th style="width:100px">DNI</th>
                            <th style="width:100px">CELULAR</th>
                            <th style="width:80px">F. NACI.</th>
                            <th style="width:50px">AÑOS</th>
                            <th style="width:50px">CODIGO</th>
                            <th style="width:60px">LOGS</th>
                            <th style="width:60px">SMS</th>
                            <th style="width:100px">OP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listClients">
                            <td>{{item.id}}</td>
                            <td class="text-center">
                                <i class="fas fa-record-vinyl"
                                    [ngClass]="{'status_active': item.status == 1, 'status_inactive': item.status == 0}"
                                    data-toggle="tooltip" data-placement="bottom" title="ACTIVO">
                                </i>
                            </td>
                            <td class="text-left">{{item.name}} {{item.surname}}</td>
                            <td class="text-center">{{item.nroDoc}}</td>
                            <td class="text-center">{{item.codPostal}}-{{item.movil}}</td>
                            <td class="text-center">{{item.fechaNac}}</td>
                            <td class="text-center">{{item.year}}</td>
                            <td class="text-center"><b>{{item.codeReg}}</b></td>
                            <td class="class_btn_op">
                                <button type="button" class="btn btn-block btn_color btn-sm" data-toggle="tooltip"
                                    data-placement="bottom" title="Ver registro de envíos"
                                    (click)="openModalLogsSms(item)">
                                    <i class="fas fa-database"></i>
                                </button>
                            </td>
                            <td class="class_btn_op">
                                <button type="button" class="btn btn-block btn_color btn-sm" data-toggle="tooltip"
                                    data-placement="bottom" title="Enviar SMS" (click)="openModalSendSms(item)">
                                    <i class="fas fa-sms"></i>
                                </button>
                            </td>
                            <td class="class_btn_op">
                                <div class="row_btn_new">
                                    <button type="button" class="btn btn-block btn_color btn-sm mr-1"
                                        data-toggle="tooltip" data-placement="bottom" title="Editar Data de Cliente"
                                        (click)="openModalFormEdit('edit', item)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button type="button" class="btn btn-block btn_color btn-sm m-0"
                                        data-toggle="tooltip" data-placement="bottom" title="Eliminar Cliente"
                                        (click)="openModalFormDelete(item)"
                                        *ngIf="dataUserLogger?.user?.idTypeUser == 1">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<!---MODAL DE EDITAR --->
<ng-template #ModalFormEdit let-modal>
    <form autocomplete="off" [formGroup]="rgRegistry" (ngSubmit)="onclickSaveData()">
        <div class="modal-header myModal-header">
            <h4 class="modal-title">
                <i class="fas fa-edit mr-1"></i> {{typeFormTitle}} CLIENTE
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body myModal-body">
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="id" class="col-sm-6 col-form-label col-form-label-sm">Codigo</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control form-control-sm" id="id" formControlName="id"
                                readonly>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="status" class="col-sm-6 col-form-label col-form-label-sm"></label>
                        <div class="col-sm-5">
                            <div class="form-check text-right">
                                <input class="form-check-input" type="checkbox" id="status" formControlName="status">
                                <label class="form-check-label" for="autoSizingCheck">
                                    Activo
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="name" class="col-sm-3 col-form-label col-form-label-sm">Nombres</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control form-control-sm" id="name" formControlName="name"
                                maxlength="20">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="surname" class="col-sm-3 col-form-label col-form-label-sm">Apellidos</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control form-control-sm" id="surname"
                                formControlName="surname" maxlength="20">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="idTypeDoc" class="col-sm-7 col-form-label col-form-label-sm">Tipo Doc.</label>
                        <div class="col-sm-5 p-0">
                            <select class="form-control form-control-sm" id="idTypeDoc" formControlName="idTypeDoc">
                                <option *ngFor="let doc of documents" value="{{doc.id}}">{{doc.document}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="nroDoc" class="col-sm-5 col-form-label col-form-label-sm">Nro. Doc</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control form-control-sm" id="nroDoc" formControlName="nroDoc"
                                maxlength="15">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-7">
                    <div class="form-group row m-0">
                        <label for="fechaNac" class="col-sm-6 col-form-label col-form-label-sm">F.
                            Nacimiento</label>
                        <div class="col-sm-6 p-0">
                            <input type="date" class="form-control form-control-sm" id="fechaNac"
                                formControlName="fechaNac">
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group row m-0">
                        <label for="year" class="col-sm-5 col-form-label col-form-label-sm">Años</label>
                        <div class="col-sm-7">
                            <input type="number" class="form-control form-control-sm" id="year" formControlName="year">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="email" class="col-sm-3 col-form-label col-form-label-sm">Email</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control form-control-sm" id="email" formControlName="email">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="phone" class="col-sm-7 col-form-label col-form-label-sm">Teléfono</label>
                        <div class="col-sm-5 p-0">
                            <input type="tel" class="form-control form-control-sm" id="phone" formControlName="phone"
                                maxlength="10">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="movil" class="col-sm-4 col-form-label col-form-label-sm">Movil</label>
                        <div class="col-sm-8">
                            <input type="tel" class="form-control form-control-sm" id="movil" formControlName="movil"
                                maxlength="10">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="codPostal" class="col-sm-7 col-form-label col-form-label-sm">Cod.
                            País</label>
                        <div class="col-sm-5 p-0">
                            <input type="text" class="form-control form-control-sm" id="codPostal"
                                formControlName="codPostal" maxlength="5">
                        </div>
                    </div>
                </div>
                <!-- <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="idCity" class="col-sm-5 col-form-label col-form-label-sm">Ciudad</label>
                        <div class="col-sm-7 pl-0">
                            <select class="form-control form-control-sm" id="idCity" formControlName="idCity">
                                <option *ngFor="let city of cities" value="{{city.id}}">{{city.city}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="modal-footer myModal-footer">
            <button type="submit" class="btn btn-ok btn-sm">
                <i class="far fa-save mr-1"></i> GUARDAR
            </button>
            <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
                <i class="fas fa-times mr-1"></i> CANCELAR
            </button>
        </div>
    </form>
</ng-template>

<!---MODAL DE LOGS --->
<ng-template #ModalLogsSMS let-modal>
    <div class="modal-header myModal-header">
        <h4 class="modal-title">
            <i class="fas fa-database mr-1"></i> LOGS DE ENVÍO SMS
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body myModal-body">
        <form>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="ModalClient" class="col-sm-3 col-form-label col-form-label-sm">Cliente</label>
                        <div class="col-sm-9 p-0">
                            <input type="text" class="form-control form-control-sm" id="ModalClient" name="ModalClient"
                                [(ngModel)]="modalClient" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group row m-0">
                        <label for="ModalMovil" class="col-sm-3 col-form-label col-form-label-sm">Movil</label>
                        <div class="col-sm-9 p-0">
                            <input type="text" class="form-control form-control-sm" id="ModalMovil" name="ModalMovil"
                                [(ngModel)]="modalMovil" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div style="height: 400px;">
            <table class="table table-hover table-striped table-head-fixed table-sm text-nowrap">
                <thead>
                    <tr>
                        <th style="width:70px">ID</th>
                        <th style="width: 80px;">FECHA</th>
                        <th>CAMPAÑA</th>
                        <th>TIPO DE SMS</th>
                        <th>ESTADO</th>
                        <th style="width:60px">OP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of listLogsSms">
                        <td class="text-center">{{log.id}}</td>
                        <td class="text-center">{{log.fechaReg}}</td>
                        <td class="text-center">{{log.nameCampaign}}</td>
                        <td class="text-center">{{log.nameTypeSMS}}</td>
                        <td class="text-center"
                            [ngClass]="{'status_active': log.status == 'ENVIADO', 'status_inactive': log.status == 'RECHAZADO'}">
                            <b>{{log.status}}</b>
                        </td>
                        <td class="class_btn_op">
                            <div class="row_btn_new">
                                <button type="button" class="btn btn-block btn_color btn-sm mr-1" data-toggle="tooltip"
                                    data-placement="bottom" title="Ver detalle" (click)="openModalFormLog(log)">
                                    <i class="far fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer myModal-footer">
        <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
            <i class="fas fa-times mr-1"></i> CERRAR
        </button>
    </div>
</ng-template>

<!---MODAL DE SMS --->
<ng-template #ModalSendSMS let-modal>
    <form autocomplete="off" [formGroup]="rgRegistrySendSMS" (ngSubmit)="onclickSaveDataSendSMS()">
        <div class="modal-header myModal-header">
            <h4 class="modal-title">
                <i class="fas fa-sms mr-1"></i> ENVÍO SMS
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body myModal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <label for="nameClient"
                                    class="col-sm-3 col-form-label col-form-label-sm">Cliente</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control form-control-sm" id="nameClient"
                                        formControlName="nameClient">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <label for="movilClient" class="col-sm-3 col-form-label col-form-label-sm">Movil</label>
                                <div class="col-sm-6">
                                    <input type="tel" class="form-control form-control-sm" id="movilClient"
                                        formControlName="movilClient">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <label for="idCampaign"
                                    class="col-sm-4 col-form-label col-form-label-sm">Campaña</label>
                                <div class="col-sm-8">
                                    <select class="form-control form-control-sm" id="idCampaign"
                                        formControlName="idCampaign">
                                        <option *ngFor="let campaign of campaigns" value="{{campaign.id}}">
                                            {{campaign.campaign}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <label for="idTypeSMS" class="col-sm-4 col-form-label col-form-label-sm">Tipo
                                    SMS</label>
                                <div class="col-sm-8">
                                    <select class="form-control form-control-sm" id="idTypeSMS"
                                        formControlName="idTypeSMS">
                                        <option *ngFor="let typeSMS of textTypeSMS" value="{{typeSMS.id}}">
                                            {{typeSMS.typeSms}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <label for="idTemplateSMS"
                                    class="col-sm-4 col-form-label col-form-label-sm">Plantilla</label>
                                <div class="col-sm-8">
                                    <select class="form-control form-control-sm" id="idTemplateSMS"
                                        formControlName="idTemplateSMS">
                                        <option *ngFor="let template of templateSMS" value="{{template.id}}">
                                            {{template.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-row d-flex justify-content-between align-items-center mb-2">
                        <div class="col-12">
                            <div class="form-group row m-0">
                                <div class="col-sm-12">
                                    <label for="bodySMS" class="col-sm-12 col-form-label col-form-label-sm pl-0">Cuerpo
                                        del SMS</label>
                                    <textarea class="form-control" id="bodySMS" rows="8" name="bodySMS"
                                        formControlName="bodySMS" maxlength="160"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer myModal-footer">
            <button type="submit" class="btn btn-ok btn-sm">
                <i class="far fa-paper-plane MR-1"></i> ENVIAR
            </button>
            <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
                <i class="fas fa-times mr-1"></i> CANCELAR
            </button>
        </div>
    </form>
</ng-template>

<!---MODAL DE ELIMINAR --->
<ng-template #ModalDelete let-modal>
    <div class="modal-header myModal-header">
        <h4 class="modal-title">
            <i class="far fa-trash-alt mr-1"></i> ELIMINAR REGISTRO
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body myModal-body">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
            <div class="row ml-0 mr-0">
                <div class="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 pr-0 pl-0" style="height: 70px;">
                    <img src="assets/img/imgEliminar.png" class="img_delete" alt="imgEliminar.png">
                </div>
                <div class="col-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
                    <div class="row">
                        <p class="text_query_delete">¿Desea eliminar el cliente seleccionado <b>{{nameItem}}</b>?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer myModal-footer">
        <button type="button" class="btn btn-ok btn-sm" (click)="modal.dismiss('delete')">
            <i class="fas fa-check mr-1"></i> SI
        </button>
        <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
            <i class="fas fa-times mr-1"></i> NO
        </button>
    </div>
</ng-template>

<!---MODAL DE EDITAR --->
<ng-template #ModalFormLog let-modal>
    <div class="modal-header myModal-header">
        <h4 class="modal-title">
            <i class="fas fa-database mr-1"></i> DETALLE DE LOG
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body myModal-body">
        <p><span class="mr-2"><b>Fecha:</b></span>{{objLog.fechaReg}}</p>
        <p><span class="mr-2"><b>Estado:</b></span>{{objLog.status}}</p>
        <p><span class="mr-2"><b>Cliente:</b></span>{{objLog.nameClient}}</p>
        <p><span class="mr-2"><b>Movil:</b></span>{{objLog.movilClient}}</p>
        <p><span class="mr-2"><b>Campaña:</b></span>{{objLog.nameCampaign}}</p>
        <p><span class="mr-2"><b>Tipo de SMS:</b></span>{{objLog.nameTypeSMS}}</p>
        <p><span class="mr-2"><b>Plantilla:</b></span>{{objLog.templateSMSName}}</p>
        <p><span class="mr-2"><b>Mensaje:</b></span>{{objLog.bodySMS}}</p>
    </div>
    <div class="modal-footer myModal-footer">
        <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
            <i class="fas fa-times mr-1"></i> CERRAR
        </button>
    </div>
</ng-template>