<div class="container-fluid p-0 body-full">
  <header id="id-header" class="id-header">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-2">
          <img src="../../../assets/img/logo-page-promo.jpg" alt="" class="img_logo">
        </div>
        <div class="col-4">
          
        </div>
      </div>
    </div>
  </header>
  <section id="id-form" class="body_promo">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12">
          <div class="box_title">
            <p>¡Gana un nuevo iPhone 14 PRO!</p>
          </div>
          <div class="box_img">
            <img src="../../../assets/img/promo_01.jpg" alt="promo_01.jpg">
          </div>
        </div>
        <div class="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-12 d-flex justify-content-center align-items-center">
          <div class="card card_form">
            <div class="title">
              <p>¡Registra tus datos para participar de esta grandiosa sorteo!</p>
            </div>
            <div class="inputs">
              <form>
                <div class="form-row mb-2">
                  <div class="col-12">
                    <input type="text" class="form-control form-control-sm" placeholder="Nombres">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-12">
                    <input type="text" class="form-control form-control-sm" placeholder="Apellidos">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-6">
                    <select class="form-control form-control-sm" id="exampleFormControlSelect1">
                      <option>DNI</option>
                      <option>Carnet de extranjería</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control form-control-sm" placeholder="Nro. Documento">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-12">
                    <input type="text" class="form-control form-control-sm" placeholder="Email para el premio">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-6">
                    <input type="text" class="form-control form-control-sm" placeholder="Teléfono">
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control form-control-sm" placeholder="Movil">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-12">
                    <input type="date" class="form-control form-control-sm" placeholder="Fecha de nacimiento">
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-6">
                    <input type="text" class="form-control form-control-sm" placeholder="Codigo Postal">
                  </div>
                  <div class="col-6">
                    <select class="form-control form-control-sm" id="exampleFormControlSelect1">
                      <option>Ciudad 01</option>
                      <option>Ciudad 02</option>
                      <option>Ciudad 03</option>
                      <option>Ciudad 04</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-12">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="chk_pc" value="option1">
                      <label class="form-check-label chk_pc" for="chk_pc">Acepto las <b><a class="a_modal"
                            (click)="openModalBaseConcurso()">bases del sorteo</a></b> y <b><a class="a_modal"
                            (click)="openModalPoliticasCondiciones()">políticas de
                            privacidad</a></b>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-4">
                  <div class="col-12">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="chk_pc" value="option1">
                      <label class="form-check-label chk_pc" for="chk_pc">Acepto la cesión de mis datos de carácter
                        personal a los <b><a class="a_modal" (click)="openModalPatrocinador()">patrocinadores</a></b> y terceras
                        empresas
                        para estas empresas puedan enviarme
                        comunicaciones comerciales de sus productos y servicios mediante el envío de emails, sms y/o
                        llamadas telefónicas, conforme se detalla en la <b><a class="a_modal"
                            (click)="openModalPoliticasCondiciones()">Política de Privacidad</a></b>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-4">
                  <div class="col-12">
                    <button type="button" class="btn btn_web btn-lg btn-block">PARTICIPAR</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!---MODAL DE BASES DE CONCURSO --->
<ng-template #ModalBaseConcurso let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-file-alt mr-1"></i> BASES DEL CONCURSO
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
      <div class="row ml-0 mr-0">
        <p class="text_policy">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
          sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
          pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
          vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
          mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
          Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
          feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam
          ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
    
          Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum.
          Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus.
          Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus
          tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget
          bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien.
          Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras ultricies
          mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget,
          imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer
          eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc. Nunc nonummy metus.
          Vestibulum volutpat pretium libero. Cras id dui. Aenean ut eros et nisl sagittis vestibulum. Nullam nulla eros,
          ultricies sit amet, nonummy id, imperdiet feugiat, pede. Sed lectus. Donec mollis hendrerit risus. Phasellus nec
          sem in justo pellentesque facilisis. Etiam imperdiet imperdiet orci. Nunc nec neque. Phasellus leo dolor, tempus
          non, auctor et, hendrerit quis, nisi. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet,
          leo. Maecenas malesuada. Praesent congue erat at massa. Sed cursus turpis vitae tortor. Donec posuere vulputate
          arcu. Phasellus accumsan cursus velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia Curae; Sed aliquam, nisi quis porttitor congue, elit erat euismod orci, ac
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE POLITICAS Y CONDICIONES --->
<ng-template #ModalPoliticasCondiciones let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-file-alt mr-1"></i> POLITICAS Y CONDICIONES
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
      <div class="row ml-0 mr-0">
        <p class="text_policy">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
          sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
          pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
          vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
          mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
          Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
          feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam
          ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
    
          Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum.
          Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus.
          Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus
          tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget
          bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien.
          Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras ultricies
          mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget,
          imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer
          eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc. Nunc nonummy metus.
          Vestibulum volutpat pretium libero. Cras id dui. Aenean ut eros et nisl sagittis vestibulum. Nullam nulla eros,
          ultricies sit amet, nonummy id, imperdiet feugiat, pede. Sed lectus. Donec mollis hendrerit risus. Phasellus nec
          sem in justo pellentesque facilisis. Etiam imperdiet imperdiet orci. Nunc nec neque. Phasellus leo dolor, tempus
          non, auctor et, hendrerit quis, nisi. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet,
          leo. Maecenas malesuada. Praesent congue erat at massa. Sed cursus turpis vitae tortor. Donec posuere vulputate
          arcu. Phasellus accumsan cursus velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia Curae; Sed aliquam, nisi quis porttitor congue, elit erat euismod orci, ac
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>

<!---MODAL DE PATROCINADORES--->
<ng-template #ModalPatrocinador let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-file-alt mr-1"></i> PATROCINADOR
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
      <div class="row ml-0 mr-0">
        <p class="text_policy">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
          sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
          pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
          vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
          mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
          Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
          feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam
          ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
    
          Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum.
          Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus.
          Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus
          tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget
          bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien.
          Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras ultricies
          mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget,
          imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer
          eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc. Nunc nonummy metus.
          Vestibulum volutpat pretium libero. Cras id dui. Aenean ut eros et nisl sagittis vestibulum. Nullam nulla eros,
          ultricies sit amet, nonummy id, imperdiet feugiat, pede. Sed lectus. Donec mollis hendrerit risus. Phasellus nec
          sem in justo pellentesque facilisis. Etiam imperdiet imperdiet orci. Nunc nec neque. Phasellus leo dolor, tempus
          non, auctor et, hendrerit quis, nisi. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet,
          leo. Maecenas malesuada. Praesent congue erat at massa. Sed cursus turpis vitae tortor. Donec posuere vulputate
          arcu. Phasellus accumsan cursus velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
          cubilia Curae; Sed aliquam, nisi quis porttitor congue, elit erat euismod orci, ac
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CERRAR
    </button>
  </div>
</ng-template>