import { ResponseQueryGeneral, ResponseQueryTextTypeSMS, SessionUserLogger, TextTypeSMS } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '@services/config.service';
import { ApiWebService } from '@services/api-web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsService } from '@services/sms.service';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-type',
  templateUrl: './sms-type.component.html',
  styleUrls: ['./sms-type.component.scss']
})
export class SmsTypeComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  nameItem: string;
  rgRegistry: FormGroup;
  dataUserLogger: SessionUserLogger;
  listCampaigns: TextTypeSMS[] = [];
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _smsService: SmsService,
    private _formBuilder: FormBuilder,
    private _dataApiSession: ApiWebService,
    private _dataConfig: ConfigurationService
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
  }

  private loadForms() {
    const model = new TextTypeSMS();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: TextTypeSMS): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      typeSms: new FormControl(
        {
          value: model.typeSms,
          disabled: false
        },
        Validators.required
      ),
    }
  }

  public listRecords() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listCampaigns = [];
    return this._smsService.getAllTextTypeSms().subscribe((result: ResponseQueryTextTypeSMS) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCampaigns = result?.result?.data; 
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public openModalFormEdit(typeForm: string, dataForm: TextTypeSMS = null) {
    this.setDataFormEdit(typeForm, dataForm);
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, dataForm: TextTypeSMS) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        let tmpUser: TextTypeSMS = new TextTypeSMS();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpUser,
          fechaReg: DateTime.now(),
          userReg: this.dataUserLogger?.user?.id
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...dataForm,
          status: (dataForm.status == 1) ? true : false,
          id: Number(dataForm.id)
        })
        break;

      default:
        break;
    }
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: TextTypeSMS = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    if(dataForm.id == 0){
      this.subscriptions.add(this.postSaveData(dataForm));
    }else{
      this.subscriptions.add(this.postEditData(dataForm));
    }
  }

  public openModalFormDelete(data: TextTypeSMS) {
    this.nameItem = data.typeSms;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { 
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }

  private postSaveData(data: TextTypeSMS): Subscription {
    return this._smsService.registerTextTypeSms(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: TextTypeSMS): Subscription {
    return this._smsService.updateTextTypeSms(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: TextTypeSMS): Subscription {
    return this._smsService.deleteTextTypeSms(data.id).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

}
