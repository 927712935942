import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersComponent } from './users/users.component';

@Component({
  selector: 'app-access-system',
  templateUrl: './access-system.component.html',
  styleUrls: ['./access-system.component.scss']
})
export class AccessSystemComponent implements OnInit {

  @ViewChild(UsersComponent) User: UsersComponent;

  constructor() { }

  ngOnInit() {
  }

  public activeTab(){
    this.User.getAllCollaborators();
  }

}
