import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MyFile, ResponseQueryGeneral } from '@/utils';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrl(): string {
        return this.config.result.apiUrl + 'tools/';
    }

    private get baseUrlImg(): string {
        return this.config.result.apiUrl + 'image/';
    }

    public linkPublicLogoSponsor(nameImg: string): string {
        return `${this.config.result.apiUrl}files/img/sponsors/${nameImg}`;
    }

    public linkPublicImgGrangePromo(nameImg: string): string {
        return `${this.config.result.apiUrl}files/img/promotions/img_grande/${nameImg}`;
    }

    public linkPublicImgMediumPromo(nameImg: string): string {
        return `${this.config.result.apiUrl}files/img/promotions/img_mini/${nameImg}`;
    }

    public linkPublicImgBackgroundPromo(nameImg: string): string {
        return `${this.config.result.apiUrl}files/img/promotions/img_fondo/${nameImg}`;
    }

    public linkPublicImgBannerPromo(nameImg: string): string {
        return `${this.config.result.apiUrl}files/img/promotions/img_banner/${nameImg}`;
    }

    public countTotalTables(): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}countTotalTables`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public dataCompanyWeb(): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}dataCompanyWeb`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public img_upload(data: MyFile): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlImg}upload`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public img_delete(data: any): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlImg}delete`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

}
