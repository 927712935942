import { Injectable } from '@angular/core';
import { ApiWebService } from '@services/api-web.service';
import { ConfigurationService } from '@services/config.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class MyMethodsService {

    constructor(
        private toastr: ToastrService,
        private dataConfig: ConfigurationService
    ) { }

    public modelToFormGroupData(model: any): any {
        const result = {};
        for (const property of Object.keys(model)) {
            result[property] = [model[property]];
        }
        return result;
    };

    /*******************************************************************/
    public showToastrSuccess(title: string, msg: string) {
        this.toastr.success(msg, title, {
            timeOut: this.dataConfig.result.toastr_timeout,
            // closeButton: true,
            progressBar: true,
            progressAnimation: 'decreasing'
        });
    }

    public showToastrWarning(title: string, msg: string) {
        this.toastr.warning(msg, title, {
            timeOut: this.dataConfig.result.toastr_timeout,
            // closeButton: true,
            progressBar: true,
            progressAnimation: 'decreasing'
        });
    }

    public showToastrError(title: string, msg: string) {
        this.toastr.error(msg, title, {
            timeOut: this.dataConfig.result.toastr_timeout,
            // closeButton: true,
            progressBar: true,
            progressAnimation: 'decreasing'
        });
    }

    /*******************************************************************/
    public setLocalStorage(token: any){
        localStorage.setItem('token', token);
    }

    public getLocalStorage(){
        
    }


}
