import { Collaborator, ResponseQueryCollaborator, ResponseQueryGeneral, ResponseQueryUser, SessionUserLogger, User, UserDTO } from '@/utils';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { CollaboratorService } from '@services/collaborator.service';
import { ConfigurationService } from '@services/config.service';
import { ApiWebService } from '@services/api-web.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/user.service';
import { Subscription } from 'rxjs';
import { DateTime } from "luxon";
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  nameItem: string;
  dataWeb: any;
  rgRegistry: FormGroup;
  dataUserLogger: SessionUserLogger;
  listUsers: UserDTO[] = [];
  listCollaborator: Collaborator[] = [];
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private _userSvr: UserService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _dataApiSession: ApiWebService,
    private _dataConfig: ConfigurationService,
    private _collaboratorSvr: CollaboratorService
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
    this._dataApiSession.getIsDataWeb().subscribe((response: any) => this.dataWeb = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    this.subscriptions.add(this.getAllCollaborators());

    this.rgRegistry.get('idCollaborator').valueChanges.subscribe((value: any) => {
      let data: Collaborator = this.listCollaborator.find(x => x.id == value);
      this.rgRegistry.patchValue({
        nameCollaborator: data?.name,
        nroDocCollaborator: data?.nroDoc,
        movilCollaborator: data?.phone
      })
    })
  }

  private loadForms() {
    const model = new UserDTO();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: UserDTO): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      idCollaborator: new FormControl(
        {
          value: model.idCollaborator,
          disabled: false
        },
        Validators.required
      ),
      nroDocCollaborator: new FormControl(
        {
          value: model.nroDocCollaborator,
          disabled: true
        },
        Validators.required
      ),
      movilCollaborator: new FormControl(
        {
          value: model.movilCollaborator,
          disabled: true
        },
        Validators.required
      ),
      idTypeUser: new FormControl(
        {
          value: model.idTypeUser,
          disabled: false
        },
        Validators.required
      ),
      nickUser: new FormControl(
        {
          value: model.nickUser,
          disabled: false
        },
        Validators.required
      ),
      passUser: new FormControl(
        {
          value: model.passUser,
          disabled: false
        },
        Validators.required
      )
    }
  }

  public listRecords(){
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listUsers = [];
    return this._userSvr.getAll().subscribe((result: ResponseQueryUser) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listUsers = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  public getAllCollaborators(): Subscription {
    this.listCollaborator = [];
    return this._collaboratorSvr.getAll().subscribe((result: ResponseQueryCollaborator) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCollaborator = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }


  public openModalFormEdit(typeForm: string, userDTO: UserDTO = null) {
    this.setDataFormEdit(typeForm, userDTO);
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  private setDataFormEdit(typeForm: string, userDTO: UserDTO) {
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        let tmpUser: UserDTO = new UserDTO();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpUser,
          fechaReg: DateTime.now(),
          userReg: this.dataUserLogger.user.id,
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...userDTO,
          status: (userDTO.status == 1) ? true : false,
          id: Number(userDTO.id),
          passUser: ''
        })
        break;

      default:
        break;
    }
  }

  public openModalFormDelete(data: UserDTO) {
    this.nameItem = data.nameCollaborator;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => {
        this.subscriptions.add(this.deleteData(data));
      }
    )
  }

  public onclickSaveData() {
    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: UserDTO = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;

    const user: User = new User({
      id: Number(dataForm.id),
      status: dataForm.status,
      idCollaborator: Number(dataForm.idCollaborator),
      idTypeUser: Number(dataForm.idTypeUser),
      nickUser: dataForm.nickUser,
      passUser: dataForm.passUser,
      fechaReg: dataForm.fechaReg,
      userReg: dataForm.userReg
    })

    if(user.id == 0){
      this.subscriptions.add(this.postSaveData(user));
    }else{
      this.subscriptions.add(this.postEditData(user));
    }
  }

  private postSaveData(data: User): Subscription {
    return this._userSvr.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: User): Subscription {
    return this._userSvr.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private deleteData(data: UserDTO): Subscription {
    return this._userSvr.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }


}
