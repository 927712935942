import { Auditoria, ResponseQueryAuditoria } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiWebService } from '@services/api-web.service';
import { AuditSysteamService } from '@services/audit-systeam.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-audit-system',
  templateUrl: './audit-system.component.html',
  styleUrls: ['./audit-system.component.scss']
})
export class AuditSystemComponent implements OnInit, OnDestroy {

  listAuditSystem: Auditoria[] = []
  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private audit: AuditSysteamService,
    private _dataApiSession: ApiWebService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAuditSystem());
  }

  public listRecords() {
    this.subscriptions.add(this.getAuditSystem());
  }

  private getAuditSystem(){
    this.listAuditSystem = [];
    return this.audit.getAll().subscribe((result: ResponseQueryAuditoria) => {
      if(result.response){
        this.listAuditSystem = result?.result?.data
      }else{
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

}
