<section class="content-header animate__animated animate__fadeIn">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="title_windows"><b><i class="fab fa-keycdn mr-1"></i>Auditoría</b></h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Configuración</li>
          <li class="breadcrumb-item active">Auditoría</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="content animate__animated animate__fadeIn">
  <div class="container-fluid">
    <div class="card mb-2">
      <div class="card-body border_top_card p-2">
        <div class="row row_search_card">
          <div class="col-sm-5">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                  BUSCAR
                </button>
                <ul class="dropdown-menu">
                  <li class="dropdown-item"><a>Nombre</a></li>
                  <li class="dropdown-item"><a>Codigo</a></li>
                  <li class="dropdown-item"><a>Documento</a></li>
                </ul>
              </div>
              <input type="text" class="form-control">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="row_btn_new">
              <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip" data-placement="bottom"
                title="Generar Reporte" (click)="listRecords()">
                <i class="fas fa-sync-alt"></i>
              </button>
              <!-- <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip" data-placement="bottom"
                title="Generar Reporte">
                <i class="fas fa-cloud-download-alt"></i>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body table-responsive p-0" style="height: 400px;">
        <table class="table table-hover table-striped table-head-fixed table-sm text-nowrap">
          <thead>
            <tr>
              <th style="width:80px">ID</th>
              <th style="width:100px">FECHA</th>
              <th style="width:80px">OPERACIÓN</th>
              <th style="width:80px">ID-USUARIO-COLABORADOR</th>
              <th style="width:80px">ID-TIPO</th>
              <th style="width:100px">VENTANA</th>
              <th style="width:50px">CODIGO</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listAuditSystem">
              <td class="text-center">{{item.id}}</td>
              <td class="text-center">{{item.fecha}}</td>
              <td class="text-left">{{item.operation}}</td>
              <td class="text-center">{{item.idUser}} - {{item.nickUser}} - {{item.nameCollaborate}}</td>
              <td class="text-center">{{item.idTypeUser}} - {{item.nameTypeUser}}</td>
              <td class="text-left">{{item.windows}}</td>
              <td class="text-left">{{item.codigo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>