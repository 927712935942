import { map } from 'lodash';
import { Model } from '../model';

export class Collaborator extends Model {
    public id: number;
    public status: number;
    public name: string;
    public idTypeDoc: number;
    public nroDoc: any;
    public email: string;
    public phone: string;
    public area: string;
    public obse: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.name = this.name || '';
        this.idTypeDoc = this.idTypeDoc || 1;
        this.nroDoc = this.nroDoc || '';
        this.email = this.email || '';
        this.phone = this.phone || '';
        this.area = this.area || 'COMERCIALIZACIÓN';
        this.obse = this.obse || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): Collaborator {
        return new Collaborator(data);
    }

    public static casts(data: object[]): Collaborator[] {
        return map(data, c => new Collaborator(c));
    }
}

export class User extends Model {
    public id: number;
    public status: number;
    public idCollaborator: number;
    public idTypeUser: number;
    public nickUser: string;
    public passUser: string;
    public fechaReg: any;
    public userReg: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.idCollaborator = this.idCollaborator || 0;
        this.idTypeUser = this.idTypeUser || 0;
        this.nickUser = this.nickUser || '';
        this.passUser = this.passUser || '';
        this.fechaReg = this.fechaReg || null;
        this.userReg = this.userReg || 'CLIENT-WEB';
    }

    public static cast(data: object): User {
        return new User(data);
    }

    public static casts(data: object[]): User[] {
        return map(data, c => new User(c));
    }
}

export class LoggerUser extends Model {
    public nickUser: string;
    public passUser: string;

    constructor(data?: object) {
        super(data);
        this.nickUser = this.nickUser || '';
        this.passUser = this.passUser || '';
    }

    public static cast(data: object): LoggerUser {
        return new LoggerUser(data);
    }

    public static casts(data: object[]): LoggerUser[] {
        return map(data, c => new LoggerUser(c));
    }
}

export class SessionUserLogger extends Model {
    public user: User;
    public collaborator: Collaborator;
    public token: string;

    constructor(data?: object) {
        super(data);
        this.user = this.user || null;
        this.collaborator = this.collaborator || null;
        this.token = this.token || '';
    }

    public static cast(data: object): SessionUserLogger {
        return new SessionUserLogger(data);
    }

    public static casts(data: object[]): SessionUserLogger[] {
        return map(data, c => new SessionUserLogger(c));
    }
}