import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaignComponent } from './campaign/campaign.component';
import { CompetitionRulesComponent } from './competition-rules/competition-rules.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { PoliciesConditionsComponent } from './policies-conditions/policies-conditions.component';
import { SmsTemplatesComponent } from './sms-templates/sms-templates.component';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit {

  @ViewChild(CampaignComponent) Campaign: CampaignComponent;
  @ViewChild(SmsTemplatesComponent) SmsTemplates: SmsTemplatesComponent;
  @ViewChild(PoliciesConditionsComponent) PoliciesConditions: PoliciesConditionsComponent;
  @ViewChild(CompetitionRulesComponent) CompetitionRules: CompetitionRulesComponent;
  @ViewChild(LegalNoticesComponent) LegalNoticesRules: LegalNoticesComponent;

  constructor() { }

  ngOnInit() {
  }

  public activeTabSponsors(){
    this.Campaign.getAllDataSponsors();
  }

  public activeTabSmsTemplates(){
    this.SmsTemplates.getAllDataTextsTypeSMS();
  }

  public activeTabPoliciesConditions(){
    this.PoliciesConditions.getAllDataCampaigns();
  }

  public activeTabCompetitionRules(){
    this.CompetitionRules.getAllDataCampaigns();
  }

  public activeTabLegalNotices(){
    this.LegalNoticesRules.getAllDataCampaigns();
  }

}
