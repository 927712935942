import {
  CampaignDTO, CompetitionRulesDTO, MyFile, PoliciesConditionsDTO, ResponseQueryCampaign, ResponseQueryClsPromo, ResponseQueryCompetitionRules,
  ResponseQueryGeneral, ResponseQueryPoliciesConditions, ResponseQueryPromo, SessionUserLogger
} from '@/utils';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PoliciesConditionsService } from '@services/policies-conditions.service';
import { CompetitionRulesService } from '@services/competition-rules.service';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { ConfigurationService } from '@services/config.service';
import { PromotionService } from '@services/promotion.service';
import { CampaignService } from '@services/campaign.service';
import { ApiWebService } from '@services/api-web.service';
import { ToolsService } from '@services/tools.service';
import { ClassificationPromo, Promotion } from '@/utils/models/promo.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { DateTime } from "luxon";

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit, OnDestroy {

  typeFormTitle: string;
  titlePromo: string;
  inputLinkWeb: string;
  objPromo: Promotion;
  rutaImgGrandePromo: any;
  nroPathImgG: number = 3;
  rutaImgMediumPromo: any;
  nroPathImgM: number = 4;
  rutaImgBackgroundPromo: any;
  nroPathImgB: number = 5;
  rutaImgBannerPromo: any;
  nroPathImgBn: number = 6;
  nameItem: string;
  textHtmlPolicies: any;
  textHtmlRules: any;
  rgRegistry: FormGroup;
  dataUserLogger: SessionUserLogger;
  listPromos: Promotion[] = [];
  listCampaigns: CampaignDTO[] = [];
  listTiposPromos: ClassificationPromo[] = [];
  listCompetitionRules: CompetitionRulesDTO[] = [];
  listPoliciesConditions: PoliciesConditionsDTO[] = [];
  fileIMGG: MyFile = new MyFile();
  fileIMGM: MyFile = new MyFile();
  fileIMGF: MyFile = new MyFile();
  fileIMGBn: MyFile = new MyFile();
  currentDay: any = DateTime.now().toFormat('y-LL-d');
  @ViewChild('ModalDelete') mdlDelete: TemplateRef<any>;
  @ViewChild('ModalFormEdit') mdlFormEdit: TemplateRef<any>;
  @ViewChild('ModalFormPC') mdlFormPC: TemplateRef<any>;
  @ViewChild('ModalFormBC') ModalFormBC: TemplateRef<any>;
  @ViewChild('ModalViewImgPromo') mdlViewImgPromo: TemplateRef<any>;
  @ViewChild('ModalGetLink') mdlGetLink: TemplateRef<any>;
  @ViewChild('ModalPagePromoLink') mdlPagePromoLink: TemplateRef<any>;
  private subscriptions: Subscription = new Subscription();
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private route: Router,
    private _mf: MyMethodsService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public _toolsService: ToolsService,
    private _dataApiSession: ApiWebService,
    private _dataConfig: ConfigurationService,
    private _campaignService: CampaignService,
    private _promotionService: PromotionService,
    private _policeSrv: PoliciesConditionsService,
    private _competitionRulesSvr: CompetitionRulesService
  ) {
    this.loadForms();
    this._dataApiSession.getIsDataUser().subscribe((response: SessionUserLogger) => this.dataUserLogger = response);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(this.getAllData());
    this.listarTablas();
    this.changeComboBox();
  }

  private changeComboBox() {
    this.rgRegistry.get('fechaIni').valueChanges.subscribe((fechaIni: any) => {
      let end: any = DateTime.fromISO(this.currentDay);
      let start: any = DateTime.fromISO(fechaIni);
      let diffInDay: any = start.diff(end, 'days');

      if (diffInDay.toObject().days <= -1) {
        this._mf.showToastrWarning('VALIDACIÓN', `La fecha inicial (${fechaIni}) no puede ver una fecha antes de la fecha actual (${this.currentDay}), , se ha ingresado automáticamente la fecha actual, Por favor verifique e ingrese la fecha correcta.`);
        this.rgRegistry.patchValue({
          fechaIni: this.currentDay
        })
        return;
      }
    })

    this.rgRegistry.get('fechaFin').valueChanges.subscribe((fechaFin: any) => {
      let fechaIni: any = this.rgRegistry.get('fechaIni').value;
      if (fechaIni == null) {
        this._mf.showToastrWarning('VALIDACIÓN', `Debe primero ingresar la FECHA INICIAL de la promoción.`);
        return;
      }

      let start: any = DateTime.fromISO(fechaFin);
      let end: any = DateTime.fromISO(fechaIni);
      let diffInDay: any = end.diff(start, 'days');

      if (diffInDay.toObject().days >= 1) {
        this._mf.showToastrWarning('VALIDACIÓN', `La fecha final (${fechaFin}) no puede ver una fecha antes de la fecha inicial (${fechaIni}), se ha ingresado automáticamente la fecha inicial, Por favor verifique e ingrese la fecha correcta.`);
        this.rgRegistry.patchValue({
          fechaFin: fechaIni
        })
        return;
      }
    })
  }

  private listarTablas() {
    this.subscriptions.add(this.getAllDataCampaigns());
    this.subscriptions.add(this.getAllDataPoliciesConditions());
    this.subscriptions.add(this.getAllDataCompetitionRules());
    this.subscriptions.add(this.getAllDataClsPromo());
  }

  public listarRegistros() {
    this.subscriptions.add(this.getAllData());
  }

  private getAllData(): Subscription {
    this.listPromos = [];
    return this._promotionService.getAll().subscribe((result: ResponseQueryPromo) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listPromos = result?.result?.data;
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private getAllDataCampaigns(): Subscription {
    this.listCampaigns = [];
    return this._campaignService.getAll().subscribe((result: ResponseQueryCampaign) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCampaigns = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private getAllDataPoliciesConditions(): Subscription {
    this.listPoliciesConditions = [];
    return this._policeSrv.getAll().subscribe((result: ResponseQueryPoliciesConditions) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listPoliciesConditions = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private getAllDataCompetitionRules(): Subscription {
    this.listCompetitionRules = [];
    return this._competitionRulesSvr.getAll().subscribe((result: ResponseQueryCompetitionRules) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listCompetitionRules = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private getAllDataClsPromo(): Subscription {
    this.listTiposPromos = [];
    return this._promotionService.getAllCls().subscribe((result: ResponseQueryClsPromo) => {
      if (result?.response) {
        if (result?.result?.data.length > 0) {
          this.listTiposPromos = result?.result?.data.filter(x => x.status == 1);
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', 'No se ha encontrado registros');
        }
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }


  private loadForms() {
    const model = new Promotion();
    const formGroupData = this.getFormGroupData(model);
    this.rgRegistry = this._formBuilder.group(formGroupData);
  }

  private getFormGroupData(model: Promotion): object {
    return {
      ...this._mf.modelToFormGroupData(model),
      name: new FormControl(
        {
          value: model.name,
          disabled: false
        },
        Validators.required
      ),
      titlePromo: new FormControl(
        {
          value: model.titlePromo,
          disabled: false
        },
        Validators.required
      ),
      idCampania: new FormControl(
        {
          value: model.idCampania,
          disabled: false
        },
        Validators.required
      ),
      idPoliticas: new FormControl(
        {
          value: model.idPoliticas,
          disabled: false
        },
        Validators.required
      ),
      idBaseConcurso: new FormControl(
        {
          value: model.idBaseConcurso,
          disabled: false
        },
        Validators.required
      ),
      idClasPromo: new FormControl(
        {
          value: model.idClasPromo,
          disabled: false
        },
        Validators.required
      ),
      fechaIni: new FormControl(
        {
          value: model.fechaIni,
          disabled: false
        },
        Validators.required
      ),
      fechaFin: new FormControl(
        {
          value: model.fechaFin,
          disabled: false
        },
        Validators.required
      ),
      pathGrandeImg: new FormControl(
        {
          value: model.pathGrandeImg,
          disabled: false
        },
        Validators.required
      ),
      pathMiniImg: new FormControl(
        {
          value: model.pathMiniImg,
          disabled: false
        },
        Validators.required
      ),
      pathImgFondo: new FormControl(
        {
          value: model.pathImgFondo,
          disabled: false
        },
        Validators.required
      ),
      pathImgBanner: new FormControl(
        {
          value: model.pathImgBanner,
          disabled: false
        },
        Validators.required
      )
    }
  }

  /*****************************************/

  public onSelectFileImgGrande(event: any) {
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      this.rgRegistry.patchValue({ pathGrandeImg: file.name });
      var reader_preview = new FileReader();
      reader_preview.readAsDataURL(file); // read file as data url
      reader_preview.onload = (event) => { // called once readAsDataURL is completed
        this.rutaImgGrandePromo = event.target.result;

        this.fileIMGG.namefile = file.name;
        this.fileIMGG.typefile = file.type;
        this.fileIMGG.option = this.nroPathImgG;

        var reader_upload = new FileReader();
        reader_upload.onload = this._handleReaderLoadedImageGrande.bind(this);
        reader_upload.readAsBinaryString(file);
      }
    }
  }

  private _handleReaderLoadedImageGrande(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.fileIMGG.base64tex = btoa(binaryString);
  }

  /*****************************************/

  public onSelectFileImgMedium(event: any) {
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      this.rgRegistry.patchValue({ pathMiniImg: file.name });
      var reader_preview = new FileReader();
      reader_preview.readAsDataURL(file); // read file as data url
      reader_preview.onload = (event) => { // called once readAsDataURL is completed
        this.rutaImgMediumPromo = event.target.result;

        this.fileIMGM.namefile = file.name;
        this.fileIMGM.typefile = file.type;
        this.fileIMGM.option = this.nroPathImgM;

        var reader_upload = new FileReader();
        reader_upload.onload = this._handleReaderLoadedImageMedium.bind(this);
        reader_upload.readAsBinaryString(file);
      }
    }
  }

  private _handleReaderLoadedImageMedium(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.fileIMGM.base64tex = btoa(binaryString);
  }

  /*****************************************/

  public onSelectFileImgBackG(event: any) {
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      this.rgRegistry.patchValue({ pathImgFondo: file.name });
      var reader_preview = new FileReader();
      reader_preview.readAsDataURL(file); // read file as data url
      reader_preview.onload = (event) => { // called once readAsDataURL is completed
        this.rutaImgBackgroundPromo = event.target.result;

        this.fileIMGF.namefile = file.name;
        this.fileIMGF.typefile = file.type;
        this.fileIMGF.option = this.nroPathImgB;

        var reader_upload = new FileReader();
        reader_upload.onload = this._handleReaderLoadedImageBack.bind(this);
        reader_upload.readAsBinaryString(file);
      }
    }
  }

  private _handleReaderLoadedImageBack(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.fileIMGF.base64tex = btoa(binaryString);
  }

  /*****************************************/

  public onSelectFileImgBanner(event: any) {
    const files = event.target.files;
    const file = files[0];

    if (files && file) {
      this.rgRegistry.patchValue({ pathImgBanner: file.name });
      var reader_preview = new FileReader();
      reader_preview.readAsDataURL(file); // read file as data url
      reader_preview.onload = (event) => { // called once readAsDataURL is completed
        this.rutaImgBannerPromo = event.target.result;

        this.fileIMGBn.namefile = file.name;
        this.fileIMGBn.typefile = file.type;
        this.fileIMGBn.option = this.nroPathImgBn;

        var reader_upload = new FileReader();
        reader_upload.onload = this._handleReaderLoadedImageBanner.bind(this);
        reader_upload.readAsBinaryString(file);
      }
    }
  }

  private _handleReaderLoadedImageBanner(readerEvent) {
    const binaryString = readerEvent.target.result;
    this.fileIMGBn.base64tex = btoa(binaryString);
  }

  /*****************************************/

  private setDataFormEdit(typeForm: string, promo: Promotion) {
    this.listarTablas();
    switch (typeForm) {
      case 'new':
        this.typeFormTitle = 'NUEVO';
        this.rutaImgGrandePromo = '';
        this.rutaImgMediumPromo = '';
        this.rutaImgBackgroundPromo = '';
        this.rutaImgBannerPromo = '';
        let tmpPromotion: Promotion = new Promotion();
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...tmpPromotion,
          fechaReg: DateTime.now(),
          userReg: Number(this.dataUserLogger.user.id),
        })
        break;

      case 'edit':
        this.typeFormTitle = 'EDITAR';
        this.rutaImgGrandePromo = this._toolsService.linkPublicImgGrangePromo(promo.pathGrandeImg);
        this.rutaImgMediumPromo = this._toolsService.linkPublicImgMediumPromo(promo.pathMiniImg);
        this.rutaImgBackgroundPromo = this._toolsService.linkPublicImgBackgroundPromo(promo.pathImgFondo);
        this.rutaImgBannerPromo = this._toolsService.linkPublicImgBannerPromo(promo.pathImgFondo);
        this.rgRegistry.reset();
        this.rgRegistry.patchValue({
          ...promo,
          id: Number(promo.id),
          status: (promo.status == 1) ? true : false
        })
        break;

      default:
        break;
    }
  }

  public onclickSaveData() {
    console.log("0 > ", this.rgRegistry.getRawValue());

    if (this.rgRegistry.invalid) {
      this._mf.showToastrWarning('VALIDACIÓN', 'Existen campos vacíos');
      return;
    }

    const dataForm: Promotion = this.rgRegistry.getRawValue();
    dataForm.status = (this.rgRegistry.getRawValue().status) ? 1 : 0;
    dataForm.linkWeb = `${this._dataConfig.result.linkWebSorteo}${this.rgRegistry.getRawValue().name}`;
    console.log("Form > ", dataForm);


    if (dataForm.id == 0) {
      if (this.fileIMGG === undefined) {
        this._mf.showToastrWarning('VALIDACIÓN', 'Aun no ha seleccionado una imagen grande, por favor seleccione una para realizar la actualización o subirla al servidor');
        return;
      }

      if (this.fileIMGM === undefined) {
        this._mf.showToastrWarning('VALIDACIÓN', 'Aun no ha seleccionado una imagen pequeña, por favor seleccione una para realizar la actualización o subirla al servidor');
        return;
      }

      if (this.fileIMGF === undefined) {
        this._mf.showToastrWarning('VALIDACIÓN', 'Aun no ha seleccionado una imagen de fondo, por favor seleccione una para realizar la actualización o subirla al servidor');
        return;
      }

      if (this.fileIMGBn === undefined) {
        this._mf.showToastrWarning('VALIDACIÓN', 'Aun no ha seleccionado una imagen del banner, por favor seleccione una para realizar la actualización o subirla al servidor');
        return;
      }

      this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGG, 'IMAGEN GRANDE'));
      this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGM, 'IMAGEN PEQUEÑA'));
      this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGF, 'IMAGEN DE FONDO'));
      this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGBn, 'IMAGEN DE BANNER'));
      this.subscriptions.add(this.postSaveData(dataForm));

      // this.subscriptions.add(this.uploadSrvImg(this.fileIMGG, this.fileIMGM, this.fileIMGF, this.fileIMGBn, dataForm, 'new'));
    } else {
      if (this.fileIMGG.namefile !== undefined || this.fileIMGG.namefile !== undefined) {
        // this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'edit'));
        this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGG, 'IMAGEN GRANDE'));
      }

      if (this.fileIMGM.namefile !== undefined || this.fileIMGM.namefile !== undefined) {
        // this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'edit'));
        this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGM, 'IMAGEN PEQUEÑA'));
      }

      if (this.fileIMGF.namefile !== undefined || this.fileIMGF.namefile !== undefined) {
        // this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'edit'));
        this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGF, 'IMAGEN DE FONDO'));
      }

      if (this.fileIMGBn.namefile !== undefined || this.fileIMGBn.namefile !== undefined) {
        // this.subscriptions.add(this.uploadSrvImg(this.fileIMG, dataForm, 'edit'));
        this.subscriptions.add(this.uploadSrvImgSimple(this.fileIMGBn, 'IMAGEN DE BANNER'));
      }

      this.subscriptions.add(this.postEditData(dataForm));
    }

  }

  private uploadSrvImgSimple(image: MyFile, tipo: string): Subscription {
    return this._toolsService.img_upload(image).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('IMAGE SUBIDA A SERVIDOR', `La ${tipo} se subió satisfactoriamente`);
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
    })
  }



  // : Subscription
  // private uploadSrvImg(imageG: MyFile, imageM: MyFile, imageF: MyFile, imageBn: MyFile, dataForm: Promotion, typOP: string) {
  //   return this._toolsService.img_upload(image).subscribe((result: ResponseQueryGeneral) => {
  //     if (result.response) {
  //       this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'La imagen se subió satisfactoriamente');
  //       switch (typOP) {
  //         case 'new':
  //           this.subscriptions.add(this.postSaveData(dataForm));
  //           break;

  //         case 'edit':
  //           this.subscriptions.add(this.postEditData(dataForm));
  //           break;

  //         default:
  //           break;
  //       }
  //     } else {
  //       this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
  //     }
  //   }, err => {
  //     this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
  //   })
  // }

  private postSaveData(data: Promotion): Subscription {
    return this._promotionService.register(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('REGISTRO SATISFACTORIO', 'los datos fueron validados y almacenados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  private postEditData(data: Promotion): Subscription {
    return this._promotionService.update(data).subscribe((result: ResponseQueryGeneral) => {
      if (result.response) {
        this._mf.showToastrSuccess('EDICIÓN SATISFACTORIA', 'los datos fueron validados y editados satisfactoriamente');
        this.subscriptions.add(this.getAllData());
        this.modalService.dismissAll();
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
      if (err.error.CodeErr == 401) {
        this._dataApiSession.logout();
        this.route.navigate(['/login']);
      }
    })
  }

  // : Subscription
  private deleteData(data: Promotion) {
    let img_oldG = { namefile: data.pathGrandeImg, option: 3 };
    let img_oldM = { namefile: data.pathMiniImg, option: 4 };
    let img_oldF = { namefile: data.pathImgFondo, option: 5 };
    let img_oldBn = { namefile: data.pathImgBanner, option: 6 };

    forkJoin([
      this._toolsService.img_delete(img_oldG),
      this._toolsService.img_delete(img_oldM),
      this._toolsService.img_delete(img_oldF),
      this._toolsService.img_delete(img_oldBn)
    ]).subscribe(() => {

      this._promotionService.delete(data.id).subscribe((result: ResponseQueryGeneral) => {
        if (result.response) {
          this._mf.showToastrSuccess('ELIMINACIÓN SATISFACTORIA', 'los datos fueron eliminados satisfactoriamente');
          this.subscriptions.add(this.getAllData());
          this.modalService.dismissAll();
        } else {
          this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
        }
      }, err => {
        this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
        if (err.error.CodeErr == 401) {
          this._dataApiSession.logout();
          this.route.navigate(['/login']);
        }
      })

    })
  }


  /**= > ABRIR MODAL: FORMULARIO REGISTRO - EDICIÓN*****/
  public openModalFormEdit(typeForm: string, promo: Promotion = null) {
    this.fileIMGG = new MyFile();
    this.fileIMGM = new MyFile();
    this.fileIMGF = new MyFile();
    this.fileIMGBn = new MyFile();
    this.setDataFormEdit(typeForm, promo);
    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlFormEdit, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  /**= > ABRIR MODAL: ELIMINAR UNA PROMOCIÓN*****/
  public openModalFormDelete(promo: Promotion) {
    this.nameItem = promo.name;
    let configModal: any = { ...this.config, size: 'sm' };
    this.modalService.open(this.mdlDelete, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => {
        this.subscriptions.add(this.deleteData(promo));
      }
    )
  }

  private getDataPolicies(idPoliticas: any) {
    this._policeSrv.getDataByID(idPoliticas).subscribe((result: ResponseQueryGeneral) => {
      if (result?.response) {
        this.textHtmlPolicies = result?.result?.textHtml;
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
    })
  }

  private getDataBasesRules(idBaseConcurso: any) {
    this._competitionRulesSvr.getDataByID(idBaseConcurso).subscribe((result: ResponseQueryGeneral) => {
      if (result?.response) {
        this.textHtmlRules = result?.result?.textHtml;
      } else {
        this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
      }
    }, err => {
      this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
    })
  }

  /**= > ABRIR MODAL: POLITICAS Y CONDICIONES*****/
  public openModalFormPoliciesConditions(idPoliticas: any) {
    if (idPoliticas == 0) {
      this._mf.showToastrWarning('VALIDACIÓN', 'No se la seleccionado las políticas y condiciones');
      return;
    }

    this.getDataPolicies(idPoliticas);
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.mdlFormPC, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  /**= > ABRIR MODAL: BASE DE CONCURSO*****/
  public openModalFormBaseConcurso(idBaseConcurso: any) {
    if (idBaseConcurso == 0) {
      this._mf.showToastrWarning('VALIDACIÓN', 'No se la seleccionado las bases del concurso');
      return;
    }

    this.getDataBasesRules(idBaseConcurso);
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.ModalFormBC, configModal).result.then(
      (result) => { this._mf.showToastrWarning('OPERACIÓN CANCELADA', '') },
      (reason) => { }
    )
  }

  /**= > ABRIR MODAL: VER IMAGEN DE PROMO GRANDE*****/
  // : Subscription
  public openModalViewImgPromo(promo: Promotion) {
    console.log(
      promo
    );
    
    this.titlePromo = `${promo.name} - ${promo.linkWeb}`;
    this.rutaImgGrandePromo = promo.pathGrandeImg;
    this.rutaImgMediumPromo = promo.pathMiniImg;
    this.rutaImgBackgroundPromo = promo.pathImgFondo;
    this.rutaImgBannerPromo = promo.pathImgBanner;

    let configModal: any = { ...this.config, size: 'lg' };
    this.modalService.open(this.mdlViewImgPromo, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  /**= > ABRIR MODAL: VER PAGINA DE PROMOCION *****/
  public openModalPageRedirect(promo: Promotion) {
    let configModal: any = { ...this.config, size: 'xl', scrollable: true };
    this.modalService.open(this.mdlPagePromoLink, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  /**= > ABRIR MODAL: OBTENER LINK DE PAGINA *****/
  public openModalGetLink(promo: Promotion) {
    this.inputLinkWeb = promo.linkWeb;
    this.objPromo = promo;
    let configModal: any = { ...this.config, size: 'md' };
    this.modalService.open(this.mdlGetLink, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  public copyLink(linkWeb: any) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(linkWeb);
      this._mf.showToastrSuccess('Link copiado', '')
    }
  }

}
