import { ResponseQueryGeneral, ResponseQueryTemplateSms, ResponseQueryTextTypeSMS, TemplateSMS, TextTypeSMS } from '@/utils';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SmsService {

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrlTemplate(): string {
        return this.config.result.apiUrl + 'template_sms/';
    }

    private get baseUrlType(): string {
        return this.config.result.apiUrl + 'textTypeSms/';
    }

    /***********************************************************/
    public getAllTemplate(): Observable<ResponseQueryTemplateSms> {
        const endpoint = `${this.baseUrlTemplate}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryTemplateSms) => res));
    }

    public getDataByIDTemplate(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlTemplate}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public registerTemplate(data: TemplateSMS): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlTemplate}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public updateTemplate(data: TemplateSMS): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlTemplate}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public deleteTemplate(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlTemplate}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    /***********************************************************/
    public getAllTextTypeSms(): Observable<ResponseQueryTextTypeSMS> {
        const endpoint = `${this.baseUrlType}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryTextTypeSMS) => res));
    }

    public getDataByIDTextTypeSms(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlType}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public registerTextTypeSms(data: TextTypeSMS): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlType}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public updateTextTypeSms(data: TextTypeSMS): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlType}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public deleteTextTypeSms(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlType}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

}
