<section class="content p-0 animate__animated animate__fadeIn">
    <div class="container-fluid p-0">
        <div class="row row_search_card mb-2">
            <div class="col-sm-5">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
                            BUSCAR
                        </button>
                        <ul class="dropdown-menu">
                            <li class="dropdown-item"><a>Usuario</a></li>
                            <li class="dropdown-item"><a>Persona</a></li>
                            <li class="dropdown-item"><a>Tipo</a></li>
                        </ul>
                    </div>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="col-sm-2">
                <div class="row_btn_new">
                    <button type="button" class="btn btn-success btn-sm mr-1" data-toggle="tooltip"
                        data-placement="bottom" title="Nuevo registro" (click)="openModalFormEdit('new')">
                        <i class="fas fa-plus"></i>
                        NUEVO
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip"
                        data-placement="bottom" title="Listar registros" (click)="listRecords()">
                        <i class="fas fa-sync-alt"></i>
                    </button>
                    <!-- <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip"
                        data-placement="bottom" title="Generar Reporte">
                        <i class="fas fa-cloud-download-alt"></i>
                    </button> -->
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body table-responsive p-0" style="height: 400px;">
                <table class="table table-hover table-striped table-head-fixed table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th style="width:70px">ID</th>
                            <th>USUARIO</th>
                            <th>PERSONA</th>
                            <th style="width:80px">NRO. DOC</th>
                            <th style="width:150px">EMAIL</th>
                            <th style="width:80px">TIPO</th>
                            <th style="width:70px">ESTADO</th>
                            <th style="width:100px">OP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listUsers">
                            <td class="text-center">{{item.id}}</td>
                            <td class="text-center">{{item.nickUser}}</td>
                            <td class="text-left">{{item.nameCollaborator}}</td>
                            <td class="text-center">{{item.nroDocCollaborator}}</td>
                            <td class="text-center">{{item.emailCollaborator}}</td>
                            <td class="text-center">{{item.nameTypeUser}}</td>
                            <td class="text-center">
                                <i class="fas fa-record-vinyl"
                                    [ngClass]="{'status_active': item.status == 1, 'status_inactive': item.status == 0}"
                                    data-toggle="tooltip" data-placement="bottom" title="ACTIVO">
                                </i>
                            </td>
                            <td class="class_btn_op">
                                <div class="row_btn_new">
                                    <button type="button" class="btn btn-block btn_color btn-sm mr-1"
                                        data-toggle="tooltip" data-placement="bottom" title="Editar Data del usuario"
                                        (click)="openModalFormEdit('edit', item)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button type="button" class="btn btn-block btn_color btn-sm m-0"
                                        data-toggle="tooltip" data-placement="bottom" title="Eliminar usuario"
                                        (click)="openModalFormDelete(item)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<!---MODAL DE EDITAR --->
<ng-template #ModalFormEdit let-modal>
    <form autocomplete="off" [formGroup]="rgRegistry" (ngSubmit)="onclickSaveData()">
        <div class="modal-header myModal-header">
            <h4 class="modal-title">
                <i class="fas fa-edit mr-1"></i> {{typeFormTitle}} USUARIO
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body myModal-body">
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="id" class="col-sm-6 col-form-label col-form-label-sm">Codigo</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control form-control-sm" id="id" formControlName="id"
                                readonly>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="status" class="col-sm-6 col-form-label col-form-label-sm"></label>
                        <div class="col-sm-5">
                            <div class="form-check text-right">
                                <input class="form-check-input" type="checkbox" id="status" formControlName="status">
                                <label class="form-check-label" for="status">
                                    Activo
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="idCollaborator"
                            class="col-sm-3 col-form-label col-form-label-sm">Colaborador</label>
                        <div class="col-sm-9">
                            <select class="form-control form-control-sm" id="idCollaborator"
                                formControlName="idCollaborator">
                                <option *ngFor="let item of listCollaborator" value="{{item.id}}">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="nroDocCollaborator" class="col-sm-7 col-form-label col-form-label-sm">Nro.
                            Doc.</label>
                        <div class="col-sm-5 p-0">
                            <input type="text" class="form-control form-control-sm" id="nroDocCollaborator"
                                formControlName="nroDocCollaborator">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row m-0">
                        <label for="movilCollaborator"
                            class="col-sm-5 col-form-label col-form-label-sm">Teléfono</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control form-control-sm" id="movilCollaborator"
                                formControlName="movilCollaborator">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="idTypeUser" class="col-sm-3 col-form-label col-form-label-sm">Tipo</label>
                        <div class="col-sm-9">
                            <select class="form-control form-control-sm" id="idTypeUser" formControlName="idTypeUser">
                                <option *ngFor="let TypeUser of dataWeb.typeusers" value="{{TypeUser.id}}">{{TypeUser.typeUser}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="nickUser" class="col-sm-3 col-form-label col-form-label-sm">Usuario</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control form-control-sm" id="nickUser"
                                formControlName="nickUser">
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row d-flex justify-content-between align-items-center mb-2">
                <div class="col-12">
                    <div class="form-group row m-0">
                        <label for="passUser" class="col-sm-3 col-form-label col-form-label-sm">Contraseña</label>
                        <div class="col-sm-7">
                            <input type="password" class="form-control form-control-sm" id="passUser"
                                formControlName="passUser" placeholder="Ingrese una nueva contraseña">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer myModal-footer">
            <button type="submit" class="btn btn-ok btn-sm">
                <i class="far fa-save mr-1"></i> GUARDAR
            </button>
            <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
                <i class="fas fa-times mr-1"></i> CANCELAR
            </button>
        </div>
    </form>
</ng-template>

<!---MODAL DE ELIMINAR --->
<ng-template #ModalDelete let-modal>
    <div class="modal-header myModal-header">
        <h4 class="modal-title">
            <i class="far fa-trash-alt mr-1"></i> ELIMINAR REGISTRO
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="modal-body myModal-body">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
            <div class="row ml-0 mr-0">
                <div class="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 pr-0 pl-0" style="height: 70px;">
                    <img src="assets/img/imgEliminar.png" class="img_delete" alt="imgEliminar.png">
                </div>
                <div class="col-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
                    <div class="row">
                        <p class="text_query_delete">¿Desea eliminar el usuario seleccionado <b>{{nameItem}}</b>?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer myModal-footer">
        <button type="button" class="btn btn-ok btn-sm" (click)="modal.dismiss('delete')">
            <i class="fas fa-check mr-1"></i> SI
        </button>
        <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
            <i class="fas fa-times mr-1"></i> NO
        </button>
    </div>
</ng-template>