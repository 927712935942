import { ClassificationPromo, Promotion, ResponseQueryClsPromo, ResponseQueryGeneral, ResponseQueryPromo } from '@/utils';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PromotionService {

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrlPromo(): string {
        return this.config.result.apiUrl + 'promotion/';
    }

    private get baseUrlCls(): string {
        return this.config.result.apiUrl + 'classificationPromo/';
    }

    public getAll(): Observable<ResponseQueryPromo> {
        const endpoint = `${this.baseUrlPromo}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryPromo) => res));
    }

    public getDataByID(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlPromo}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public register(data: Promotion): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlPromo}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public update(data: Promotion): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlPromo}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public delete(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrlPromo}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    /*************************************************/

    public getAllCls(): Observable<ResponseQueryClsPromo> {
        const endpoint = `${this.baseUrlCls}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClsPromo) => res));
    }

    public getDataByIDCls(id: number): Observable<ResponseQueryClsPromo> {
        const endpoint = `${this.baseUrlCls}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClsPromo) => res));
    }

    public registerCls(data: ClassificationPromo): Observable<ResponseQueryClsPromo> {
        const endpoint = `${this.baseUrlCls}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClsPromo) => res));
    }

    public updateCls(data: ClassificationPromo): Observable<ResponseQueryClsPromo> {
        const endpoint = `${this.baseUrlCls}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClsPromo) => res));
    }

    public deleteCls(id: number): Observable<ResponseQueryClsPromo> {
        const endpoint = `${this.baseUrlCls}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClsPromo) => res));
    }

}
