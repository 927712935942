import { Client, ResponseQueryClient, ResponseQueryGeneral } from '@/utils';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    userItelvox: string = 'midas';
    passItelvox: string = 'peV.jm7h'

    constructor(
        private http: HttpClient,
        private tokenApi: ApiService,
        private config: ConfigurationService
    ) { }

    private get baseUrl(): string {
        return this.config.result.apiUrl + 'client/';
    }

    public getAll(): Observable<ResponseQueryClient> {
        const endpoint = `${this.baseUrl}getAll`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryClient) => res));
    }

    public getDataByID(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}getDataByID/${id}`;
        return this.http.get(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public register(data: Client): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}register`;
        return this.http.post(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public update(data: Client): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}update/${data.id}`;
        return this.http.put(endpoint, data, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public delete(id: number): Observable<ResponseQueryGeneral> {
        const endpoint = `${this.baseUrl}delete/${id}`;
        return this.http.delete(endpoint, this.tokenApi.requestOptions).pipe(map((res: ResponseQueryGeneral) => res));
    }

    public sendSMSclient(data: any): Observable<any> {
        const endpoint = `https://api2.itelvox.com:8002/api?username=${this.userItelvox}&password=${this.passItelvox}&ani=${data.ani}&dnis=${data.dnis}&message=${data.message}&command=submit&dataCoding=0&serviceType=SMS&longMessageMode=split`;
        return this.http.get(endpoint).pipe(map((res: any) => res));
    }

    public sendSMSclientv01(data: any): Observable<any> {
        const endpoint = `https://api2.itelvox.com:8002/api?username=${this.userItelvox}&password=${this.passItelvox}&ani=${data.ani}&dnis=${data.dnis}&message=${data.message}&command=submit&dataCoding=0&serviceType=SMS&longMessageMode=split`;
        return this.http.post(endpoint, data).pipe(map((res: any) => res));
    }

    public sendSMSclientv02(data: any): Observable<any> {
        const endpoint = `http://api2.itelvox.com:8001/api?username=${this.userItelvox}&password=${this.passItelvox}&ani=${data.ani}&dnis=${data.dnis}&message=${data.message}&command=submit&dataCoding=0&serviceType=SMS&longMessageMode=split`;
        return this.http.post(endpoint, data).pipe(map((res: any) => res));
    }

}
