<section class="content p-0 animate__animated animate__fadeIn">
  <div class="container-fluid p-0">
    <div class="row row_search_card mb-2">
      <div class="col-sm-5">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
              BUSCAR
            </button>
            <ul class="dropdown-menu">
              <li class="dropdown-item"><a>Nombre</a></li>
              <li class="dropdown-item"><a>Campaña</a></li>
            </ul>
          </div>
          <input type="text" class="form-control">
        </div>
      </div>
      <div class="col-sm-2">
        <div class="row_btn_new">
          <button type="button" class="btn btn-success btn-sm mr-1" data-toggle="tooltip" data-placement="bottom"
            title="Nuevo registro" (click)="openModalFormEdit('new')">
            <i class="fas fa-plus"></i>
            NUEVO
          </button>
          <button type="button" class="btn btn-secondary btn-sm m-0" data-toggle="tooltip" data-placement="bottom"
            title="Listar registros" (click)="listRecords()">
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body table-responsive p-0" style="height: 400px;">
        <table class="table table-hover table-striped table-head-fixed table-sm text-nowrap">
          <thead>
            <tr>
              <th style="width:80px">ID</th>
              <th style="width:100px">FECHA</th>
              <th style="width:250px">CAMPAÑA</th>
              <th>POLÍTICAS Y CONDICIONES</th>
              <th style="width:50px">ESTADO</th>
              <th style="width:50px">DETALLE</th>
              <th style="width:100px">OP</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listPoliciesConditions">
              <td>{{item.id}}</td>
              <td>{{item.fechaReg}}</td>
              <td>{{item.nameCampaign}}</td>
              <td>{{item.name}}</td>
              <td>
                <i class="fas fa-record-vinyl"
                  [ngClass]="{'status_active': item.status == 1, 'status_inactive': item.status == 0}"
                  data-toggle="tooltip" data-placement="bottom" title="ACTIVO">
                </i>
              </td>
              <td class="class_btn_op">
                <button type="button" class="btn btn-block btn_color btn-sm mr-1" data-toggle="tooltip"
                  data-placement="bottom" title="" (click)="openModalDetailTemplate(item)">
                  <i class="fas fa-eye"></i>
                </button>
              </td>
              <td class="class_btn_op">
                <div class="row_btn_new">
                  <button type="button" class="btn btn-block btn_color btn-sm mr-1" data-toggle="tooltip"
                    data-placement="bottom" title="Editar Data de Plantilla" (click)="openModalFormEdit('edit', item)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button type="button" class="btn btn-block btn_color btn-sm m-0" data-toggle="tooltip"
                    data-placement="bottom" title="Eliminar Plantilla" (click)="openModalFormDelete(item)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<!---MODAL DE EDITAR --->
<ng-template #ModalFormEdit let-modal>
  <form autocomplete="off" [formGroup]="rgRegistry" (ngSubmit)="onclickSaveData()">
    <div class="modal-header myModal-header">
      <h4 class="modal-title">
        <i class="fas fa-edit mr-1"></i> {{typeFormTitle}} POLÍTICAS Y CONDICIONES
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body myModal-body">
      <div class="form-row d-flex justify-content-between align-items-center mb-2">
        <div class="col-4">
          <div class="form-group row m-0">
            <label for="idCampaign" class="col-sm-4 col-form-label col-form-label-sm">Campaña</label>
            <div class="col-sm-8">
              <select class="form-control form-control-sm" id="idCampaign" formControlName="idCampaign">
                <option *ngFor="let campaign of listCampaigns" value="{{campaign.id}}">{{campaign.campaign}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group row m-0">
            <label for="name" class="col-sm-3 col-form-label col-form-label-sm">Nombre</label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" id="name" formControlName="name">
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group row m-0">
            <div class="col-sm-3">
              <div class="form-check text-right">
                <input class="form-check-input" type="checkbox" id="status" formControlName="status">
                <label class="form-check-label" for="status">
                  Activo
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div formControlName="textHtml" [ngxSummernote]="configSummerNote"></div>
    </div>
    <div class="modal-footer myModal-footer">
      <button type="submit" class="btn btn-ok btn-sm">
        <i class="far fa-save mr-1"></i> GUARDAR
      </button>
      <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
        <i class="fas fa-times mr-1"></i> CANCELAR
      </button>
    </div>
  </form>
</ng-template>

<!---MODAL DE ELIMINAR --->
<ng-template #ModalDelete let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="far fa-trash-alt mr-1"></i> ELIMINAR REGISTRO
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
      <div class="row ml-0 mr-0">
        <div class="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 pr-0 pl-0" style="height: 70px;">
          <img src="assets/img/imgEliminar.png" class="img_delete" alt="imgEliminar.png">
        </div>
        <div class="col-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">
          <div class="row">
            <p class="text_query_delete">¿Desea eliminar la Politicas y condiciones seleccionada <b>{{nameItem}}</b>?</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" (click)="modal.dismiss('delete')">
      <i class="fas fa-check mr-1"></i> SI
    </button>
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> NO
    </button>
  </div>
</ng-template>

<!---MODAL DE EDITAR --->
<ng-template #ModalDetailPC let-modal>
  <div class="modal-header myModal-header">
    <h4 class="modal-title">
      <i class="fas fa-edit mr-1"></i> POLÍTICAS Y CONDICIONES
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body myModal-body">
    <div [innerHTML]="bodyHtml"></div>
  </div>
  <div class="modal-footer myModal-footer">
    <button type="button" class="btn btn-ok btn-sm" ngbAutofocus (click)="modal.close('close')">
      <i class="fas fa-times mr-1"></i> CANCELAR
    </button>
  </div>
</ng-template>