<section class="content-header animate__animated animate__fadeIn">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="title_windows"><b><i class="fas fa-table mr-1"></i>Entidades</b></h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                    <li class="breadcrumb-item">Configuración</li>
                    <li class="breadcrumb-item active">Entidades</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="row">
        <div class="col-12 col-sm-12">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0 animate__animated animate__fadeIn">
                    <ul class="nav nav-tabs myCustom_navTabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="tab-sponsors" data-toggle="pill"
                                href="#custom-tab-sponsors" role="tab" aria-controls="custom-sponsors"
                                aria-selected="false"><b>PATROCINADORES</b>
                            </a>
                        </li>
                        <li class="nav-item" (click)="activeTabSponsors()">
                            <a class="nav-link active" id="tab-campaign" data-toggle="pill"
                                href="#custom-tab-campaign" role="tab" aria-controls="custom-tab-campaign"
                                aria-selected="true"><b>CAMPAÑAS</b>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-sms-type" data-toggle="pill"
                                href="#custom-tab-sms-type" role="tab" aria-controls="custom-tab-sms-type"
                                aria-selected="false"><b>TIPOS DE SMS</b>
                            </a>
                        </li>
                        <li class="nav-item" (click)="activeTabSmsTemplates()">
                            <a class="nav-link" id="tab-sms-templates" data-toggle="pill"
                                href="#custom-tab-sms-templates" role="tab" aria-controls="custom-tab-sms-templates"
                                aria-selected="false"><b>PLANTILLAS DE SMS</b>
                            </a>
                        </li>
                        <li class="nav-item" (click)="activeTabPoliciesConditions()">
                            <a class="nav-link" id="tab-policies-conditions" data-toggle="pill"
                                href="#custom-tab-policies-conditions" role="tab" aria-controls="custom-tab-policies-conditions"
                                aria-selected="false"><b>POLÍTICAS Y CONDICIONES</b>
                            </a>
                        </li>
                        <li class="nav-item" (click)="activeTabLegalNotices()">
                            <a class="nav-link" id="tab-legal-notices" data-toggle="pill"
                                href="#custom-tab-legal-notices" role="tab" aria-controls="custom-tab-legal-notices"
                                aria-selected="false"><b>AVISOS LEGALES</b>
                            </a>
                        </li>
                        <li class="nav-item" (click)="activeTabCompetitionRules()">
                            <a class="nav-link" id="tab-competition-rules" data-toggle="pill"
                                href="#custom-tab-competition-rules" role="tab" aria-controls="custom-tab-competition-rules"
                                aria-selected="false"><b>BASES DEL CONCURSO</b>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade" id="custom-tab-sponsors" role="tabpanel"
                            aria-labelledby="custom-tab-sponsors-tab">
                            <app-sponsors></app-sponsors>
                        </div>
                        <div class="tab-pane fade show active" id="custom-tab-campaign" role="tabpanel"
                            aria-labelledby="custom-tab-campaign-tab">
                            <app-campaign></app-campaign>
                        </div>
                        <div class="tab-pane fade" id="custom-tab-sms-type" role="tabpanel"
                            aria-labelledby="custom-tab-sms-type-tab">
                            <app-sms-type></app-sms-type>
                        </div>
                        <div class="tab-pane fade" id="custom-tab-sms-templates" role="tabpanel"
                            aria-labelledby="custom-tab-sms-templates-tab">
                            <app-sms-templates></app-sms-templates>
                        </div>
                        <div class="tab-pane fade" id="custom-tab-policies-conditions" role="tabpanel"
                            aria-labelledby="custom-tab-policies-conditions-tab">
                            <app-policies-conditions></app-policies-conditions>
                        </div>
                        <div class="tab-pane fade" id="custom-tab-legal-notices" role="tabpanel"
                            aria-labelledby="custom-tab-legal-notices-tab">
                            <app-legal-notices></app-legal-notices>
                        </div>
                        <div class="tab-pane fade" id="custom-tab-competition-rules" role="tabpanel"
                            aria-labelledby="custom-tab-competition-rules">
                        <app-competition-rules></app-competition-rules>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>