import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-web-page-promo',
  templateUrl: './web-page-promo.component.html',
  styleUrls: ['./web-page-promo.component.scss']
})
export class WebPagePromoComponent implements OnInit {

  @ViewChild('ModalBaseConcurso') mdlBaseConcurso: TemplateRef<any>;
  @ViewChild('ModalPoliticasCondiciones') mdlPoliticasCondiciones: TemplateRef<any>;
  @ViewChild('ModalPatrocinador') mdlPatrocinador: TemplateRef<any>;
  config: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder', ariaLabelledBy: 'modal-basic-title' };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  public openModalBaseConcurso() {
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.mdlBaseConcurso, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  public openModalPoliticasCondiciones() {
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.mdlPoliticasCondiciones, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

  public openModalPatrocinador() {
    let configModal: any = { ...this.config, size: 'lg', scrollable: true };
    this.modalService.open(this.mdlPatrocinador, configModal).result.then(
      (result) => { },
      (reason) => { }
    )
  }

}
