import { map } from 'lodash';
import { Model } from '../model';

export class Client extends Model {
    public id: number;
    public status: number;
    public name: string;
    public surname: string;
    public idTypeDoc: number;
    public nroDoc: string;
    public fechaNac: any;
    public year: number;
    public email: string;
    public phone: string;
    public movil: string;
    public codPostal: any;
    public idCity: number;
    public fechaReg: any;
    public userReg: any;
    public codeReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.name = this.name || '';
        this.surname = this.surname || '';
        this.idTypeDoc = this.idTypeDoc || 0;
        this.nroDoc = this.nroDoc || '';
        this.fechaNac = this.fechaNac || '';
        this.year = this.year || 0;
        this.email = this.email || '';
        this.phone = this.phone || '';
        this.movil = this.movil || '';
        this.codPostal = this.codPostal || '';
        this.idCity = this.idCity || 1;
        this.fechaReg = this.fechaReg || '';
        this.userReg = this.userReg || 'CLIENT-WEB';
        this.codeReg = this.codeReg || '';
    }

    public static cast(data: object): Client {
        return new Client(data);
    }

    public static casts(data: object[]): Client[] {
        return map(data, c => new Client(c));
    }
}

export class logSendSmsDTO extends Model {
    public id: number;
    public status: string;
    public idCliente: number;
    public nameClient: string;
    public movilClient: string;
    public codPostalClient: string;
    public idCampaign: number;
    public nameCampaign: string;
    public idTypeSMS: number;
    public nameTypeSMS: string;
    public idTemplateSMS: number;
    public templateSMSName: string;
    public bodySMS: string;
    public fechaReg: any;
    public userReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || '';
        this.idCliente = this.idCliente || 0;
        this.nameClient = this.nameClient || '';
        this.movilClient = this.movilClient || '';
        this.codPostalClient = this.codPostalClient || '';
        this.idCampaign = this.idCampaign || 0;
        this.nameCampaign = this.nameCampaign || '';
        this.idTypeSMS = this.idTypeSMS || 0;
        this.nameTypeSMS = this.nameTypeSMS || '';
        this.idTemplateSMS = this.idTemplateSMS || 0;
        this.templateSMSName = this.templateSMSName || '';
        this.bodySMS = this.bodySMS || '';
        this.fechaReg = this.fechaReg || '';
        this.userReg = this.userReg || '';

    }

    public static cast(data: object): logSendSmsDTO {
        return new logSendSmsDTO(data);
    }

    public static casts(data: object[]): logSendSmsDTO[] {
        return map(data, c => new logSendSmsDTO(c));
    }
}