<div class="container-fluid animate__animated animate__fadeIn p-0" style="margin-top: 74px;">
	<br>
	<div class="bodySigIn ">
		<div class="form">
			<div class="thumbnail">
				<img src="../../../assets/img/logo-page-promo.jpg" alt="logo-page-promo.png">
			</div>
			<form class="login-form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
				<input type="email" placeholder="Ingrese su usuario" id="nickUser" name="nickUser" formControlName="nickUser">
				<input type="password" placeholder="Ingrese su contraseña" id="passUser" name="passUser" formControlName="passUser">
				<button type="submit" class="mt-2" [disabled]="!loginForm.valid">INICIAR SESIÓN</button>
			</form>
		</div>
	</div>
	<br>
</div>
