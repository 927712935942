import { map } from 'lodash';
import { Model } from '../model';

export class PromotionDTO extends Model {
    public id: number;
    public status: number;
    public name: string;
    public idCampania: number;
    public nameCampania: string;
    public idPoliticas: number;
    public namePoliticas: string;
    public idBaseConcurso: number;
    public nameBaseConcurso: string;
    public pathImg: string;
    public linkWeb: string;
    public fechaIni: string;
    public fechaFin: any;
    public fechaReg: any;


    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.name = this.name || '';
        this.idCampania = this.idCampania || 0;
        this.idPoliticas = this.idPoliticas || 0;
        this.idBaseConcurso = this.idBaseConcurso || 0;
        this.linkWeb = this.linkWeb || '';
        this.pathImg = this.pathImg || '';
        this.fechaIni = this.fechaIni || null;
        this.fechaFin = this.fechaFin || null;
        this.fechaReg = this.fechaReg || null;
    }

    public static cast(data: object): PromotionDTO {
        return new PromotionDTO(data);
    }

    public static casts(data: object[]): PromotionDTO[] {
        return map(data, c => new PromotionDTO(c));
    }
}

export class ClientDTO extends Model {
    public id: number;
    public status: number;
    public name: string;
    public surname: string;
    public idTypeDoc: number;
    public nameTypeDoc: string;
    public nroDoc: string;
    public fechaNac: any;
    public year: number;
    public email: string;
    public phone: string;
    public movil: string;
    public codPostal: any;
    public idCity: number;
    public nameCity: string;
    public fechaReg: any;
    public userReg: any;
    public codeReg: any;

    constructor(data?: object) {
        super(data);
        this.id = this.id || 0;
        this.status = this.status || 0;
        this.name = this.name || '';
        this.surname = this.surname || '';
        this.idTypeDoc = this.idTypeDoc || 1;
        this.nameTypeDoc = this.nameTypeDoc || '';
        this.nroDoc = this.nroDoc || '';
        this.fechaNac = this.fechaNac || '';
        this.year = this.year || 0;
        this.email = this.email || '';
        this.phone = this.phone || '';
        this.movil = this.movil || '';
        this.codPostal = this.codPostal || '';
        this.idCity = this.idCity || 1;
        this.nameCity = this.nameCity || '';
        this.fechaReg = this.fechaReg || '';
        this.userReg = this.userReg || 'CLIENT-WEB';
        this.codeReg = this.codeReg || '';
    }

    public static cast(data: object): ClientDTO {
        return new ClientDTO(data);
    }

    public static casts(data: object[]): ClientDTO[] {
        return map(data, c => new ClientDTO(c));
    }
}