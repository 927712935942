import { ResponseQueryGeneral } from '@/utils';
import { MyMethodsService } from '@/utils/Myfunctions/my-methods.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiWebService } from '@services/api-web.service';
import { ToolsService } from '@services/tools.service';
import { DateTime } from "luxon";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    dateNow: any;
    nroCampaign: number = 0;
    nroPromos: number = 0;
    nroClients: number = 0;
    nroSendSMS: number = 0;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private _router: Router,
        private _mf: MyMethodsService,
        private _toolsSrv: ToolsService,
        private _dataApiSession: ApiWebService,
    ) {

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.dateNow = DateTime.now().toFormat('dd-LL-y');
        this.subscriptions.add(this.getCountTables());
    }

    private getCountTables(): Subscription {
        return this._toolsSrv.countTotalTables().subscribe((result: ResponseQueryGeneral) => {
            if (result.response) {
                this.nroCampaign = result.result.nro_campaigns;
                this.nroPromos = result.result.nro_promotions;
                this.nroClients = result.result.nro_clients;
                this.nroSendSMS = result.result.nro_logs;
            } else {
                this._mf.showToastrWarning('RESPUESTA DE SERVIDOR', result?.errors);
            }
        }, err => {
            this._mf.showToastrError('OCURRIÓ UN ERROR INTERNO', JSON.stringify(err.error));
            if (err.error.CodeErr == 401) {
                this._dataApiSession.logout();
                this._router.navigate(['/login']);
            }
        })
    }

}
