import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@services/app.service';
import { ApiWebService } from '@services/api-web.service';
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private _dataApiSession: ApiWebService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.getSession();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    async getSession() {
        try {
            var dt = DateTime.now();
            let tmp_day: any = dt.minus({ days: 1 });
            let Yesterday: any = DateTime.fromISO(tmp_day).toFormat('dd-LL-y');
    
            /**ELIMINAMOS SESIONES DEL DIA ANTERIOR*********/
            localStorage.removeItem(`_t${Yesterday}`);
            localStorage.removeItem(`_u${Yesterday}`);
            localStorage.removeItem(`_w${Yesterday}`);
    
            /***********/
            let dayNow: any = DateTime.now().toFormat('dd-LL-y');
            let token: any = localStorage.getItem(`_t${dayNow}`);
            let dataUser: any = localStorage.getItem(`_u${dayNow}`);
            let dataWeb: any = localStorage.getItem(`_w${dayNow}`);
    
            if (token) {
                let tmp_token: any = decodeURIComponent(escape(window.atob(token)));
                this._dataApiSession.setIsTokenWeb(tmp_token);
    
                if (dataUser) {
                    let tmp_dataUser: any = JSON.parse(decodeURIComponent(escape(window.atob(dataUser))))
                    this._dataApiSession.setIsLoadUser(true);
                    this._dataApiSession.setIsDataUser(tmp_dataUser);
    
                    if (dataWeb) {
                        let tmp_dataWeb: any = JSON.parse(decodeURIComponent(escape(window.atob(dataWeb))))
                        this._dataApiSession.setIsDataWeb(tmp_dataWeb);
                        return true;
                    } else {
                        this.router.navigate(['/login']);
                        return false;
                    }
                } else {
                    this.router.navigate(['/login']);
                    return false;
                }
            } else {
                this.router.navigate(['/login']);
                return false;
            }
        } catch (error) {
            return false;
        }

    }
}
